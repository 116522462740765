import {
  ADD_NEW_GL_LINE_SUCCESS,
  CLEAR_ALL_GL_FORM,
  CLEAR_CREDIT_FORM,
  CLEAR_DEBIT_FORM,
  COPY_NARRATION,
  CREDIT_ITEMS_DELETE,
  CREDIT_ITEMS_EDIT,
  CREDIT_ITEMS_SUBMIT,
  DEBIT_ITEMS_DELETE,
  DEBIT_ITEMS_EDIT,
  DEBIT_ITEMS_SUBMIT,
  EDIT_GL_ENTRY_FAIL,
  EDIT_GL_ENTRY_SUCCESS,
  EXPORT_GL_ENTRY_FAIL,
  EXPORT_GL_ENTRY_REQUEST,
  EXPORT_GL_ENTRY_SUCCESS,
  GET_ALL_GL_ENTRY_FAIL,
  GET_ALL_GL_ENTRY_REQUEST,
  GET_ALL_GL_ENTRY_SUCCESS,
  GET_COA_LIST_FAIL,
  GET_COA_LIST_REQUEST,
  GET_COA_LIST_SAVE,
  GET_CURRENCIES_FAIL,
  GET_CURRENCIES_REQUEST,
  GET_CURRENCIES_SAVE,
  GET_GL_TAX_LIST_FAIL,
  GET_GL_TAX_LIST_REQ,
  GET_GL_TAX_LIST_SUCCESS,
  GL_ATTACHMENTS_DELETE,
  GL_ATTACHMENTS_REQUEST,
  GL_ATTACHMENTS_SUCCESS,
  GL_DETAILS_REQUEST,
  GL_DETAILS_SAVE,
  GL_LINES_DETAILS_REQUEST,
  GL_LINES_DETAILS_SAVE,
  GL_VALIDATE_DATE_FAIL,
  GL_VALIDATE_DATE_REQUEST,
  GL_VALIDATE_DATE_SUCCESS,
  SUBMIT_GL_ENTRY_FAIL,
  SUBMIT_GL_ENTRY_REQUEST,
  SUBMIT_GL_ENTRY_SUCCESS,
  UPDATE_ITEMS_SUBMIT
} from './Constant'
import axios from 'axios'
import { SessionExpried } from '../../Functions';

// entering data to form
export const CreateAccount = (name, value) => async dispatch => {
  try {
    dispatch({ type: GL_DETAILS_REQUEST })

    dispatch({ type: GL_DETAILS_SAVE, payload: { name, value } })
  } catch (error) {
    console.log(error)
  }
}
// entering data to Lines
export const createLineRow = (name, value, index, row) => async dispatch => {
  try {
    dispatch({ type: GL_LINES_DETAILS_REQUEST })

    dispatch({ type: GL_LINES_DETAILS_SAVE, payload: { name, value, index, row } })
  } catch (error) {
    console.log(error)
  }
}
// Add new Line Row
export const addLineRow = () => async dispatch => {
  try {
    dispatch({ type: ADD_NEW_GL_LINE_SUCCESS, payload: '' })
  } catch (error) {
    console.log(error)
  }
}
// clear All Form
export const clearForm = () => async dispatch => {
  try {
    dispatch({ type: CLEAR_ALL_GL_FORM, payload: '' })
  } catch (error) {
    console.log(error)
  }
}
// clear debit lines
export const clearDebitForm = () => async dispatch => {
  try {
    dispatch({ type: CLEAR_DEBIT_FORM, payload: '' })
  } catch (error) {
    console.log(error)
  }
}
// clear credit lines
export const clearCreditForm = () => async dispatch => {
  try {
    dispatch({ type: CLEAR_CREDIT_FORM, payload: '' })
  } catch (error) {
    console.log(error)
  }
}
// copy narration and paste on line description

export const copyNarrtion = (index, row) => async dispatch => {
  dispatch({ type: COPY_NARRATION, payload: { index, row } })
}

export const DebitItemsSubmit = editIndex => async dispatch => {
  dispatch({ type: DEBIT_ITEMS_SUBMIT, payload: { editIndex } })
}

export const updateLineItems = (lines) => async dispatch => {
  dispatch({ type: UPDATE_ITEMS_SUBMIT, payload: { lines } })
}

export const GLAttachments = fileData => async dispatch => {
  try {
    dispatch({ type: GL_ATTACHMENTS_REQUEST })

    dispatch({ type: GL_ATTACHMENTS_SUCCESS, payload: fileData })
  } catch (error) {

  }
}
export const GLAttachmentsDelete = fileIndex => async dispatch => {
  dispatch({ type: GL_ATTACHMENTS_DELETE, payload: fileIndex })
}

export const DebitItemsDelete = (isCredit, Index) => async dispatch => {
  dispatch({ type: DEBIT_ITEMS_DELETE, payload: isCredit, Index })
}

export const DebitItemsEdit = (row, index, isEdit) => async dispatch => {
  dispatch({ type: DEBIT_ITEMS_EDIT, payload: { row, index, isEdit } })
}

export const CreditItemsSubmit = editIndex => async dispatch => {
  dispatch({ type: CREDIT_ITEMS_SUBMIT, payload: { editIndex } })
}

export const CreditItemsDelete = Index => async dispatch => {
  dispatch({ type: CREDIT_ITEMS_DELETE, payload: Index })
}

export const CreditItemsEdit = (row, index, Edit) => async dispatch => {
  dispatch({ type: CREDIT_ITEMS_EDIT, payload: { row, index, Edit } })
}
// getting COA Accounts List
export const getAccountList = (token, navigate, Dispatch) => async dispatch => {
  try {
    dispatch({ type: GET_COA_LIST_REQUEST })

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/lov/getListofChartsOfAccountwithoutGroup?search=${''}`,
      {
        headers: { authorization: token }

      }
    )

    dispatch({ type: GET_COA_LIST_SAVE, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({ type: GET_COA_LIST_FAIL, payload: error.message });
    }
  }
}
// getting Currencies
export const getCurrencies = Token => async dispatch => {
  try {
    dispatch({ type: GET_CURRENCIES_REQUEST })

    const { data } = await axios.get(
      `https://app.invoicemate.net/pro/api/lookup/GetOrgCurrencies/602241c47a18fc82a41cd973`
      // {
      //   headers: { authjwt: Token }
      // }
    )
    dispatch({ type: GET_CURRENCIES_SAVE, payload: data })
  } catch (error) {
    dispatch({ type: GET_CURRENCIES_FAIL, payload: error.message })
  }
}
// getting Tax List
export const getTaxList = (token, navigate, Dispatch) => async dispatch => {
  try {
    dispatch({ type: GET_GL_TAX_LIST_REQ })

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/lov/getTaxList`,
      {
        headers: { authorization: token }
      }
    )
    dispatch({ type: GET_GL_TAX_LIST_SUCCESS, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({ type: GET_GL_TAX_LIST_FAIL, payload: error.message });
    }
  }
}

// VALIDATE DATE 
export const validateDate = (value, token, navigate, Dispatch) => async dispatch => {
  try {
    dispatch({ type: GL_VALIDATE_DATE_REQUEST })

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/financialConfiguration/checkDate`,

      headers: { authorization: token },
      data: { journalDate: value }
    })
    dispatch({ type: GL_VALIDATE_DATE_SUCCESS, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({ type: GL_VALIDATE_DATE_FAIL, payload: error.message })
    }
  }
}

// submit new GL Entries
export const submitGLEntries = (GLEntry, lineRows, edit, status, addAnother, narration, token, navigate, Dispatch) => async dispatch => {
  let DebitLines = lineRows.filter(item => !item.isCredit)
  let CreditLines = lineRows.filter(item => item.isCredit)
  let formData = {
    journalDate: new Date(GLEntry.values.journalDate),
    statusType: status,
    source: GLEntry.values.source,
    description: GLEntry.values.narration,
    taxType: GLEntry.values.taxType,
    addDebitLines: DebitLines,
    addCreditLines: CreditLines,
    attachments: GLEntry.saved_attachments,
    voucherRef: GLEntry.values.voucherRef,
    creditSubTotal: GLEntry.values.creditSubTotal,
    creditTaxAmt: GLEntry.values.creditTaxAmt,
    creditTotal: GLEntry.values.creditTotal,
    debitSubTotal: GLEntry.values.debitSubTotal,
    debitTaxAmt: GLEntry.values.debitTaxAmt,
    debitTotal: GLEntry.values.debitTotal,
    narationStatus: narration
  }
  try {
    dispatch({ type: SUBMIT_GL_ENTRY_REQUEST })
    const { data } = await axios({
      method: 'post',
      url: !edit ? `${process.env.REACT_APP_API_URL}/glEntry/addGLEntry` :
        `${process.env.REACT_APP_API_URL}/glEntry/updateGLEntry`,

      headers: { authorization: token }
      ,
      data: formData
    })
    dispatch({ type: SUBMIT_GL_ENTRY_SUCCESS, payload: { addAnother } })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({
        type: SUBMIT_GL_ENTRY_FAIL,
        payload: error.response?.data || ''
      })
    }
  }
}
// Get All GL Entires
export const getAllGLEntries = (page, rowPerPage, search, type, token, navigate, Dispatch) => async dispatch => {
  try {
    dispatch({ type: GET_ALL_GL_ENTRY_REQUEST })
    const { data } = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/glEntry/getListofGLEntry?pageNumber=${page}&pageSize=${rowPerPage}&statusType=${type}&search=${search}&paging=${true}`,

      headers: { authorization: token }

    })
    dispatch({ type: GET_ALL_GL_ENTRY_SUCCESS, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({
        type: GET_ALL_GL_ENTRY_FAIL,
        payload: error.response?.data || ''
      })
    }
  }
}

// Edit GL Entry

export const editGLEntry = (row, type, accountList) => async dispatch => {
  try {
    dispatch({ type: EDIT_GL_ENTRY_SUCCESS, payload: { row, type, accountList } })
  } catch (error) {
    dispatch({
      type: EDIT_GL_ENTRY_FAIL,
      payload: ''
    })
  }
}

export const exportGLEntries = (page, rowPerPage, search, token, navigate, Dispatch) => async dispatch => {
  try {
    dispatch({ type: EXPORT_GL_ENTRY_REQUEST })
    const { data } = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/glEntry/GLExportToCsv?pageNumber=${page}&pageSize=${rowPerPage}&search=${search}`,
      headers: { authorization: token }

    })

    dispatch({ type: EXPORT_GL_ENTRY_SUCCESS, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({
        type: EXPORT_GL_ENTRY_FAIL,
        payload: error.response?.data || ''
      })
    }
  }
}

