import { CallToActionSharp } from '@material-ui/icons'
import {
  CREATE_TAX_REQUEST,
  CREATE_TAX_SUCCESS,
  CLEAR_TAX_FORM,
  SUBMIT_TAX_DETAILS_REQUEST,
  SUBMIT_TAX_DETAILS_SUCCESS,
  SUBMIT_TAX_DETAILS_FAIL,
  GET_ALL_TAX_REQUEST,
  GET_ALL_TAX_SUCCESS,
  GET_ALL_TAX_FAIL,
  GET_ALL_TAX_TYPES_REQUEST,
  GET_ALL_TAX_TYPES_SUCCESS,
  GET_ALL_TAX_TYPES_FAIL,
  EDIT_TAX_SUCCESS,
  EDIT_TAX_FAIL,
  GET_ALL_COA_ACCT_SUCCESS,
  ADD_NEW_TAX_ROW_REQUEST,
  ADD_NEW_TAX_ROW_SUCCESS,
  CREATE_TAX_ROW_REQUEST,
  CREATE_TAX_ROW_SUCCESS,
  DELETE_TAX_ROW_SUCCESS,
} from '../Actions/Constant'

let initialState = {
  taxDetails: {
    displayName: '',
    description: '',
    value: '',
    coaId: '',
    taxValue: 0,
    create: true,
    view: false,
    edit: false
  },
  CSV_Success: false,
  CSV_Url: '',
  loading: false,
  taxRows: [{
    description: "",
    value: 0,
    coaId: '',
    coa:'',
    errorDescription: "",
    errorValue: 0,
    errorCoaId: '',
  }],
  accounts: [],
  taxList: [],
  COAList: [],
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: ''
}

export function CreateTax(state = initialState, action) {
  switch (action.type) {
    // clear All
    case CLEAR_TAX_FORM:
      return {
        ...state,
        taxDetails: {
          ...state.taxDetails,
          displayName: '',
          description: '',
          value: '',
          coaId: '',
          taxValue: 0,
          create: true,
          view: false,
          edit: false
        },
        taxRows: [{
          description: "",
          value: 0,
          coaId: '',
          coa:'',
          errorDescription: "",
          errorValue: 0,
          errorCoaId: '',
        }],
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }
    // getting all COA

    // case GET_ALL_COA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     inProgress: true,
    //     error: false,
    //     coaList: []
    //   }
    // case GET_ALL_COA_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     inProgress: false,
    //     isSuccess: true,
    //     error: false,
    //     coaList: action.payload
    //   }
    // case GET_ALL_COA_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: true,
    //     inProgress: false,
    //     isSuccess: false,
    //     coaList: [],
    //     errorMsg: action.payload
    //   }

    //  Save New Account Details
    case CREATE_TAX_REQUEST:
      return { ...state }
    case CREATE_TAX_SUCCESS:
      return {
        ...state,
        taxDetails: {
          ...state.taxDetails,
          [action.payload.name]: action.payload.value
        }
      }

    //  Save New Tax Row Details
    case CREATE_TAX_ROW_REQUEST:
      return { ...state }
    case CREATE_TAX_ROW_SUCCESS:
      let rows_new = [...state.taxRows]
      let object = { ...rows_new[action.payload.index], [action.payload.name]: action.payload.value }
      rows_new[action.payload.index] = object
      return {
        ...state,
        taxRows: rows_new
      }

    case ADD_NEW_TAX_ROW_REQUEST:
      return { ...state }
    case ADD_NEW_TAX_ROW_SUCCESS:

      let rows = state.taxRows

      let newRow = {
        description: "",
        value: 0,
        coaId: '',
        coa:'',
        errorDescription: "",
        errorValue: 0,
        errorCoaId: '',
      }
      let taxArray = [...rows, newRow]
      return {
        ...state,
        taxRows: taxArray
      }

    case DELETE_TAX_ROW_SUCCESS:
      let filterArray = state.taxRows.filter(
        (a, index) => index !== action.payload.index
      )
      return {
        ...state,
        taxRows: filterArray
      }

    //  Get Account Types
    case GET_ALL_TAX_SUCCESS:
      return { ...state, taxList: action.payload }

    // Edit Account
    case EDIT_TAX_SUCCESS:
      const { type } = action.payload
      const {
        displayName,
        taxCompnents,
        create,
        view,
        _id,
        edit } = action.payload.row
      return {
        ...state,
        taxRows:taxCompnents,
        taxDetails: {
          ...state.taxDetails,
          displayName: displayName,
          id: _id,
          create: type === 'view' || type === 'edit' ? false : true,
          view: type === 'view' ? true : false,
          edit: type === 'edit' ? true : false
        },
      }

    // export to CSV 
    case GET_ALL_COA_ACCT_SUCCESS:
      return { ...state, COAList: action.payload }

    // submit new tax 
    case SUBMIT_TAX_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        inProgress: true,
        isSuccess: false,
        errorMsg: ''
      }
    case SUBMIT_TAX_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        inProgress: false,
        isSuccess: true,
        errorMsg: ''
      }
    case SUBMIT_TAX_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        inProgress: false,
        isSuccess: false,
        errorMsg: action.payload
      }
    default:
      return state
  }
}
