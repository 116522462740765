import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { chartofAccounts } from "./ChartofAccount";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { GLFormEntries } from "./GLForm";
import { PaymentAccountDetails } from "./PaymentAcctDetails";
import { DespositVoucherReducer } from "./DepositVoucher";
import { PaymentVoucherReducer } from "./PaymentVoucher";
import { CreateSubAccount } from "./CreateSubAccount";
import { CreateTax } from "./AddTax";
import { financialSetting } from "./FinancialSetting";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { GeneralLedgerReport } from "./GeneralLedgerReport";
import { SaleTransactionReport } from "./SaleTransactionReport";
import { TrailBalance } from "./TrailBalance";
import { GLIquiryReducer } from "./GLInquiry";
import { ProfitLossReducer } from "./ProfitLoss";
import { GeneralSetupReducer } from "./GeneralSetup";
import { BalanceSheet } from "./BalanceSheet";
import { CashFlowReducer } from "./CashFlowStatement";
import { DashboardReducer } from "./Dashboard";
import { exportToExcel } from "./Export";
import userReducer from "./user";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2, // ADDED
};

const rootReducer = combineReducers({
  user: userReducer,
  chartofAccounts: chartofAccounts,
  subAccounts: CreateSubAccount,
  addTax: CreateTax,
  financialSetting: financialSetting,
  GLForm: GLFormEntries,
  AccountInfo: PaymentAccountDetails,
  DepositVoucher: DespositVoucherReducer,
  PaymentVoucher: PaymentVoucherReducer,
  GeneralLedgerReport: GeneralLedgerReport,
  SaleTransactionReport: SaleTransactionReport,
  TrailBalance: TrailBalance,
  BalanceSheet: BalanceSheet,
  GLIquiry: GLIquiryReducer,
  ProfitLoss: ProfitLossReducer,
  CashFlow: CashFlowReducer,
  generalSetup: GeneralSetupReducer,
  Dashboard: DashboardReducer,
  export: exportToExcel
});

// export default persistReducer(persistConfig, rootReducer)
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
