import moment from 'moment'
import {
  CLEAR_ALL_PAYMENT_VOUCHER_FORM,
  PAYMENT_CLEAR_LINE_ITEMS,
  EDIT_PAYMENT_VOUCHER_DETAILS_SUCCESS,
  EXPORT_PAYMENT_VOUCHER_SUCCESS,
  GET_ALL_PAYMENT_VOUCHERS_FAIL,
  GET_ALL_PAYMENT_VOUCHERS_REQUEST,
  GET_ALL_PAYMENT_VOUCHERS_SUCCESS,
  _GET_PAYMENT_ACCT_FAIL,
  _GET_PAYMENT_ACCT_REQUEST,
  _GET_PAYMENT_ACCT_SUCCESS,
  PAYMENT_LINE_ITEMS_DELETE,
  PAYMENT_LINE_ITEMS_EDIT,
  PAYMENT_LINE_ITEMS_SUBMIT,
  SUBMIT_PAYMENT_VOUCHER_FAIL,
  SUBMIT_PAYMENT_VOUCHER_REQUEST,
  SUBMIT_PAYMENT_VOUCHER_SUCCESS,
  PAYMENT_VOUCHER_DETAILS_REQUEST,
  PAYMENT_VOUCHER_DETAILS_SAVE,
  COA_LIST_SAVE,
  PAYMENT_VOUCHER_LINES_DETAILS_REQUEST,
  PAYMENT_VOUCHER_LINES_DETAILS_SAVE,
  ADD_NEW_LINE_SUCCESS,
  LINES_DELETE,
  DOWNLOAD_PAYMENT_VOUCHER_SUCCESS,
  DOWNLOAD_PAYMENT_VOUCHER_FAIL,
  DOWNLOAD_PAYMENT_VOUCHER_REQUEST,
  CLEAR_DOWNLOAD_PAYMENT_VOUCHER,
  PAYMENT_VOUCHER_VALIDATE_DATE_REQUEST,
  PAYMENT_VOUCHER_VALIDATE_DATE_SUCCESS,
  PAYMENT_VOUCHER_VALIDATE_DATE_FAIL,
  PAYMENT_ATTACHMENTS_REQUEST,
  PAYMENT_ATTACHMENTS_SUCCESS,
  PAYMENT_ATTACHMENTS_DELETE,
  LINES_UPDATE
} from '../Actions/Constant'

let initialState = {
  VoucherDetails: {
    values: {
      depositDate: moment(new Date()).format('YYYY-MM-DD'),
      depositType: '',
      toAccount: '',
      depositMode: '',
      receivedForm: '',
      voucherRef: '',
      instrumentNumber: '',
      totalAmount: '',
      depositerName: '',
      description: '',
      source: '',
      coaId: '',
      accountTitle: '',
      memo: '',
      creditAmount: '',
      creditTaxAmt: '',
      TotalOutBy: '',
      creditSubTotal: '',
      taxType: 'NoTax',
      create: true,
      view: false,
      edit: false
    },
    credit_Lines: [],
  },
  CSV_Success: false,
  lineRows: [{
    coaId: '',
    coa: '',
    errorCoa: '',
    isCredit: false,
    lineDescription: '',
    creditAmount: 0,
    errorCredit: '',
    TaxRate: 0,
    taxRateId: '',
    errorTaxRate: '',
    errorTaxAmt: '',
    TaxAmt: 0,
    taxValue: 0,
  }],
  CSV_Url: '',
  downloadLoading: false,
  downloadLink: '',
  saved_attachments: [],
  accountList: [],
  paymentAcctList: [],
  VoucherList: [],
  loading: false,
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: '',
  validateLoading: false,
  validateSuccess: false,
  validateError: false,
  validationMsg: ''
}

export function PaymentVoucherReducer(state = initialState, action) {
  switch (action.type) {
    //  Save New Account Details
    case PAYMENT_VOUCHER_DETAILS_REQUEST:
      return { ...state }
    case PAYMENT_VOUCHER_DETAILS_SAVE:
      return {
        ...state,
        VoucherDetails: {
          ...state.VoucherDetails,
          values: {
            ...state.VoucherDetails.values,
            [action.payload.name]: action.payload.value
          }
        }
      }

    // Save Attachments
    case PAYMENT_ATTACHMENTS_REQUEST:
      return { ...state }
    case PAYMENT_ATTACHMENTS_SUCCESS:
      const fileData = action.payload
      let attachArray = state.saved_attachments;
      attachArray = [...state.saved_attachments, fileData]
      return {
        ...state,
        saved_attachments: attachArray,
      }
    case PAYMENT_ATTACHMENTS_DELETE:
      let attachments_detail = state.saved_attachments
      let attach = attachments_detail.filter(
        (a, index) => index !== action.payload
      )
      return {
        ...state,
        saved_attachments: attach,
      }

    // VALIDATE DATE
    case PAYMENT_VOUCHER_VALIDATE_DATE_REQUEST:
      return {
        ...state,
        validateLoading: true,
        validateSuccess: false,
        validateError: false,
        validationMsg: ''
      }
    case PAYMENT_VOUCHER_VALIDATE_DATE_SUCCESS:
      return {
        ...state,
        validateLoading: false,
        validateSuccess: true,
        validateError: false,
        validationMsg: action.payload
      }
    case PAYMENT_VOUCHER_VALIDATE_DATE_FAIL:
      return {
        ...state,
        validateLoading: false,
        validateSuccess: false,
        validateError: true,
        validationMsg: ''

      }
    case LINES_UPDATE:
      const { lines } = action.payload
      return {
        ...state,
        lineRows: lines,
      };

    //  Save New Line Row Details
    case PAYMENT_VOUCHER_LINES_DETAILS_REQUEST:
      return { ...state }
    case PAYMENT_VOUCHER_LINES_DETAILS_SAVE:
      let rows_new = [...state.lineRows]
      let object = { ...rows_new[action.payload.index], [action.payload.name]: action.payload.value }
      rows_new[action.payload.index] = object
      return {
        ...state,
        lineRows: rows_new
      }
    // Add new Line Row
    case ADD_NEW_LINE_SUCCESS:
      let rows = state.lineRows
      let newRow = {
        coaId: '',
        coa: '',
        errorCoa: '',
        isCredit: false,
        lineDescription: '',
        creditAmount: 0,
        errorCredit: '',
        TaxRate: 0,
        taxRateId: '',
        errorTaxRate: '',
        errorTaxAmt: '',
        TaxAmt: 0,
        taxValue: 0,
      }
      let lineArray = [...rows, newRow]
      return {
        ...state,
        lineRows: lineArray
      }
    // Delete Lines
    case LINES_DELETE:

      let _Lines = state.lineRows.filter(
        (a, index) => index !== action.Index
      )
      return {
        ...state,
        lineRows: _Lines
      }
    // clear All Form
    case CLEAR_ALL_PAYMENT_VOUCHER_FORM:
      return {
        ...state,
        VoucherDetails: {
          ...state.VoucherDetails,
          values: {
            ...state.VoucherDetails.values,
            depositDate: moment(new Date()).format('YYYY-MM-DD'),
            depositType: '',
            toAccount: '',
            depositMode: '',
            receivedForm: '',
            voucherRef: '',
            instrumentNumber: '',
            totalAmount: '',
            depositerName: '',
            description: '',
            source: '',
            coaId: '',
            accountTitle: '',
            memo: '',
            creditAmount: '',
            taxType: 'NoTax',
            create: true,
            view: false,
            edit: false
          },
          credit_Lines: [],
        },
        saved_attachments: [],
        lineRows: [{
          coaId: '',
          coa: '',
          errorCoa: '',
          isCredit: false,
          lineDescription: '',
          creditAmount: 0,
          errorCredit: '',
          TaxRate: 0,
          taxRateId: '',
          errorTaxRate: '',
          errorTaxAmt: '',
          TaxAmt: 0,
          taxValue: 0,
        }],
        downloadLoading: false,
        downloadLink: '',
        loading: false,
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: '',
        validateLoading: false,
        validateSuccess: false,
        validateError: false,
        validationMsg: ''
      }
    // clear All CREDIT Form
    case PAYMENT_CLEAR_LINE_ITEMS:
      return {
        ...state,
        lineRows: [{
          coaId: '',
          coa: '',
          errorCoa: '',
          isCredit: false,
          lineDescription: '',
          creditAmount: 0,
          errorCredit: '',
          TaxRate: 0,
          taxRateId: '',
          errorTaxRate: '',
          errorTaxAmt: '',
          TaxAmt: 0,
          taxValue: 0,
        }]
      }
    // Credit Lines
    case PAYMENT_LINE_ITEMS_SUBMIT:
      let New_Credit_Details = {
        coaId: state.VoucherDetails.values.coaId,
        accountTitle: state.VoucherDetails.values.accountTitle,
        memo: state.VoucherDetails.values.memo,
        creditAmount: state.VoucherDetails.values.creditAmount,
        accountName: state.VoucherDetails.values.coaAccountName.name
      }
      let CreditArray = state.VoucherDetails.credit_Lines

      if (action.payload.editIndex !== null) {
        CreditArray[action.payload.editIndex] = New_Credit_Details
      } else {
        CreditArray = [...state.VoucherDetails.credit_Lines, New_Credit_Details]
      }
      return {
        ...state,
        VoucherDetails: {
          ...state.VoucherDetails,
          credit_Lines: CreditArray,
          values: {
            ...state.VoucherDetails.values,
            coaId: '',
            accountTitle: '',
            memo: '',
            creditAmount: ''
          }
        }
      }
    case PAYMENT_LINE_ITEMS_DELETE:
      let credit_Array = state.VoucherDetails.credit_Lines
      let filter_Credit_Lines = credit_Array.filter(
        (a, index) => index !== action.payload
      )
      return {
        ...state,
        VoucherDetails: {
          ...state.VoucherDetails,
          credit_Lines: filter_Credit_Lines
        }
      }
    case PAYMENT_LINE_ITEMS_EDIT:
      const { Edit } = action.payload
      const {
        coaId,
        accountTitle,
        memo,
        creditAmount
      } = action.payload.row
      return {
        ...state,
        VoucherDetails: {
          ...state.VoucherDetails,
          values: {
            ...state.VoucherDetails.values,
            coaId: coaId,
            accountTitle: accountTitle,
            memo: memo,
            creditAmount: creditAmount
          }
        }
      }
    //saving COA List
    case COA_LIST_SAVE:
      return { ...state, accountList: action.payload }

    //saving Payment Accounts
    case _GET_PAYMENT_ACCT_SUCCESS:
      return { ...state, paymentAcctList: action.payload }

    //SUBMIT new Voucher Deatils

    case SUBMIT_PAYMENT_VOUCHER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        inProgress: true,
        isSuccess: false,
        errorMsg: ''
      }
    case SUBMIT_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        inProgress: false,
        isSuccess: true,
        errorMsg: ''
      }
    case SUBMIT_PAYMENT_VOUCHER_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        inProgress: false,
        isSuccess: false,
        errorMsg: action.payload
      }

    // Download Entry
    case DOWNLOAD_PAYMENT_VOUCHER_REQUEST:
      return {
        ...state,
        downloadLoading: true,
        downloadLink: ''
      }
    case DOWNLOAD_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        downloadLoading: false,
        downloadLink: action.payload
      }
    case DOWNLOAD_PAYMENT_VOUCHER_FAIL:
      return {
        ...state,
        downloadLoading: false,
        downloadLink: ''
      }
    // Clear Download Entry
    case CLEAR_DOWNLOAD_PAYMENT_VOUCHER:
      return {
        ...state,
        downloadLoading: false,
        downloadLink: ''
      }

    // getting all Payment Vouchers
    case GET_ALL_PAYMENT_VOUCHERS_REQUEST:
      return {
        ...state,
        inProgress: true
      }
    case GET_ALL_PAYMENT_VOUCHERS_SUCCESS:
      return {
        ...state,
        inProgress: false,
        VoucherList: action.payload
      }
    case GET_ALL_PAYMENT_VOUCHERS_FAIL:
      return {
        ...state,
        inProgress: false,
        VoucherList: []
      }
    //  Edit GL Entry
    case EDIT_PAYMENT_VOUCHER_DETAILS_SUCCESS:
      const { type } = action.payload
      const {
        _id,
        depositDate,
        createdAt,
        journalDate,
        paymentMode,
        fromAccount,
        paymentType,
        voucherRef,
        instrumentNumber,
        totalAmount,
        receivedFrom,
        payName,
        source,
        addCreditLines,
        addDebitLines,
        payTo,
        attachments,
        taxType

      } = action.payload.row
      let toAccount = fromAccount?._id
      let newLine = addDebitLines.map((line) => ({ ...line, creditAmount: line.debitAmount }))
      let debitLine = addCreditLines[0]
      return {
        ...state,
        VoucherDetails: {
          ...state.VoucherDetails,
          values: {
            ...state.VoucherDetails.values,
            id: _id,
            taxType: taxType,
            depositDate: moment(journalDate).format('YYYY-MM-DD'),
            depositType: paymentType,
            toAccount: toAccount ? toAccount : fromAccount,
            depositMode: paymentMode,
            receivedForm: payTo,
            voucherRef: voucherRef.toUpperCase(),
            totalAmount: totalAmount,
            depositerName: payName,
            description: debitLine.lineDescription,
            source: source,
            create: type === 'view' || type === 'edit' ? false : true,
            view: type === 'view' ? true : false,
            edit: type === 'edit' ? true : false
          },
        },
        lineRows: newLine,
        saved_attachments: attachments,
      }
    // export to CSV
    case EXPORT_PAYMENT_VOUCHER_SUCCESS:
      return { ...state, CSV_Success: true, CSV_Url: action.payload.path }
    default:
      return state
  }
}
