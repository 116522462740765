import { SET_PERMISSIONS, SET_TOKEN } from "../Actions/Constant";

const initialState = {
  Token: null,
  isTokenExpired: false,
  userListData: {},
  organizations: [],
  companies: [],
  departments: [],
  teams: [],
  titles: [],
  notifications: [],
  unreadedNotifications: 0,
  tasks: [],
  unreadedTasks: 0,
  events: [],
  darkmode: false,
  isAr: false,
  tabVal: 0,
  permissions: null,
  isApEnable: false,
  isArEnable: false
};

const userReducer = (state = initialState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        Token: action.payload.token,
        userData: action.payload.userData
      }
   
    case SET_PERMISSIONS:  
    return {
        ...state,
        permissions: action.payload
      }
    default:
      return { ...state };
  }
};
export default userReducer;
