import {
  CLEAR_ALL_PAYMENT_VOUCHER_FORM,
  PAYMENT_CLEAR_LINE_ITEMS,
  EDIT_PAYMENT_VOUCHER_DETAILS_FAIL,
  EDIT_PAYMENT_VOUCHER_DETAILS_SUCCESS,
  EXPORT_PAYMENT_VOUCHER_FAIL,
  EXPORT_PAYMENT_VOUCHER_REQUEST,
  EXPORT_PAYMENT_VOUCHER_SUCCESS,
  GET_ALL_PAYMENT_VOUCHERS_FAIL,
  GET_ALL_PAYMENT_VOUCHERS_REQUEST,
  GET_ALL_PAYMENT_VOUCHERS_SUCCESS,
  _GET_PAYMENT_ACCT_FAIL,
  _GET_PAYMENT_ACCT_REQUEST,
  _GET_PAYMENT_ACCT_SUCCESS,
  PAYMENT_LINE_ITEMS_DELETE,
  PAYMENT_LINE_ITEMS_EDIT,
  PAYMENT_LINE_ITEMS_SUBMIT,
  SUBMIT_PAYMENT_VOUCHER_FAIL,
  SUBMIT_PAYMENT_VOUCHER_REQUEST,
  SUBMIT_PAYMENT_VOUCHER_SUCCESS,
  PAYMENT_VOUCHER_DETAILS_REQUEST,
  PAYMENT_VOUCHER_DETAILS_SAVE,
  COA_LIST_FAIL,
  COA_LIST_REQUEST,
  COA_LIST_SAVE,
  PAYMENT_VOUCHER_LINES_DETAILS_REQUEST,
  PAYMENT_VOUCHER_LINES_DETAILS_SAVE,
  ADD_NEW_LINE_SUCCESS,
  LINES_DELETE,
  DOWNLOAD_PAYMENT_VOUCHER_REQUEST,
  DOWNLOAD_PAYMENT_VOUCHER_SUCCESS,
  DOWNLOAD_PAYMENT_VOUCHER_FAIL,
  CLEAR_DOWNLOAD_PAYMENT_VOUCHER,
  PAYMENT_VOUCHER_VALIDATE_DATE_REQUEST,
  PAYMENT_VOUCHER_VALIDATE_DATE_SUCCESS,
  PAYMENT_VOUCHER_VALIDATE_DATE_FAIL,
  PAYMENT_ATTACHMENTS_REQUEST,
  PAYMENT_ATTACHMENTS_SUCCESS,
  PAYMENT_ATTACHMENTS_DELETE,
  LINES_UPDATE,
} from "./Constant";
import axios from "axios";
import { SessionExpried } from "../../Functions";

const token = localStorage.getItem("authjwt");

// entering data to form
export const CreateAccount = (name, value) => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_VOUCHER_DETAILS_REQUEST });

    dispatch({ type: PAYMENT_VOUCHER_DETAILS_SAVE, payload: { name, value } });
  } catch (error) {
    console.log(error);
  }
};

// VALIDATE DATE
export const validateDate =
  (value, token, navigate, Dispatch) => async (dispatch) => {
    try {
      dispatch({ type: PAYMENT_VOUCHER_VALIDATE_DATE_REQUEST });

      const { data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/financialConfiguration/checkDate`,

        headers: { authorization: token },
        data: { journalDate: value },
      });
      dispatch({ type: PAYMENT_VOUCHER_VALIDATE_DATE_SUCCESS, payload: data });
    } catch (error) {
      if (error?.response?.status === 401) {
        SessionExpried(navigate, Dispatch);
      } else {
        dispatch({
          type: PAYMENT_VOUCHER_VALIDATE_DATE_FAIL,
          payload: error.message,
        });
      }
    }
  };

// entering data to Lines
export const createLineRow = (name, value, index, row) => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_VOUCHER_LINES_DETAILS_REQUEST });

    dispatch({
      type: PAYMENT_VOUCHER_LINES_DETAILS_SAVE,
      payload: { name, value, index, row },
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateLineItems = (lines) => async dispatch => {
  dispatch({ type: LINES_UPDATE, payload: { lines } })
}

// Add new Line Row
export const addLineRow = () => async (dispatch) => {
  try {
    dispatch({ type: ADD_NEW_LINE_SUCCESS, payload: "" });
  } catch (error) {
    console.log(error);
  }
};
// Delete Line Row
export const lineDelete = (isCredit, Index) => async (dispatch) => {
  dispatch({ type: LINES_DELETE, payload: isCredit, Index });
};
// clear All Form
export const clearForm = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ALL_PAYMENT_VOUCHER_FORM, payload: "" });
  } catch (error) {
    console.log(error);
  }
};
// clear line Items
export const clearCreditForm = () => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_CLEAR_LINE_ITEMS, payload: "" });
  } catch (error) {
    console.log(error);
  }
};

export const CreditItemsSubmit = (editIndex) => async (dispatch) => {
  dispatch({ type: PAYMENT_LINE_ITEMS_SUBMIT, payload: { editIndex } });
};

export const CreditItemsDelete = (Index) => async (dispatch) => {
  dispatch({ type: PAYMENT_LINE_ITEMS_DELETE, payload: Index });
};

export const CreditItemsEdit = (row, index, Edit) => async (dispatch) => {
  dispatch({ type: PAYMENT_LINE_ITEMS_EDIT, payload: { row, index, Edit } });
};
// getting COA Accounts List
export const getAccountList = (Token) => async (dispatch) => {
  try {
    dispatch({ type: COA_LIST_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/lov/getListofChartsOfAccount`,
      {
        headers: { authorization: token },
      }
    );

    dispatch({ type: COA_LIST_SAVE, payload: data });
  } catch (error) {
    dispatch({ type: COA_LIST_FAIL, payload: error.message });
  }
};
// submit new GL Entries
export const submitVoucher =
  (VoucherDetails, lineRows, edit, saved_attachments, token, navigate, Dispatch) =>
    async (dispatch) => {
      let formData = {
        _id: VoucherDetails.values.id,
        taxType: VoucherDetails.values.taxType,
        depositDate: new Date(VoucherDetails.values.depositDate),
        paymentType: VoucherDetails.values.depositType,
        fromAccount: VoucherDetails.values.toAccount,
        paymentMode: VoucherDetails.values.depositMode,
        payTo: VoucherDetails.values.receivedForm,
        voucherRef: VoucherDetails.values.voucherRef,
        totalAmount: Number(VoucherDetails.values.totalAmount),
        payName: VoucherDetails.values.depositerName,
        description: VoucherDetails.values.description,
        addcreditLine: lineRows,
        attachments: saved_attachments,
      };
      try {
        dispatch({ type: SUBMIT_PAYMENT_VOUCHER_REQUEST });
        const { data } = await axios({
          method: "post",
          url: !edit
            ? `${process.env.REACT_APP_API_URL}/PaymentVoucher/addPaymentVoucher`
            : `${process.env.REACT_APP_API_URL}/PaymentVoucher/updatePaymentVoucher`,
          headers: { authorization: token },
          data: formData,
        });
        dispatch({ type: SUBMIT_PAYMENT_VOUCHER_SUCCESS, payload: data });
      } catch (error) {
        if (error?.response?.status === 401) {
          SessionExpried(navigate, Dispatch);
        } else {
          dispatch({
            type: SUBMIT_PAYMENT_VOUCHER_FAIL,
            payload: error.response?.data || "",
          });
        }
      }
    };

// Download Entry
export const downloadEntry =
  (id, type, token, navigate, Dispatch) => async (dispatch) => {
    try {
      dispatch({ type: DOWNLOAD_PAYMENT_VOUCHER_REQUEST });

      const { data } = await axios({
        method: "get",
        url:
          type === "PDF"
            ? `${process.env.REACT_APP_API_URL}/PaymentVoucher/paymentPDF/${id}`
            : `${process.env.REACT_APP_API_URL}/PaymentVoucher/getVoucherCSV/${id}`,
        headers: { authorization: token },
      });
      dispatch({ type: DOWNLOAD_PAYMENT_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      if (error?.response?.status === 401) {
        SessionExpried(navigate, Dispatch);
      } else {
        dispatch({
          type: DOWNLOAD_PAYMENT_VOUCHER_FAIL,
          payload: error?.response?.data
            ? error.response?.data
            : error?.response?.msg,
        });
      }
    }
  };

// Clear Download Entry
export const handleClearDownload = () => async (dispatch) => {
  dispatch({ type: CLEAR_DOWNLOAD_PAYMENT_VOUCHER, payload: "" });
};

// Get All GL Entires
export const getAllVouchers =
  (page, rowPerPage, search, token, navigate, Dispatch) => async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_PAYMENT_VOUCHERS_REQUEST });

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/PaymentVoucher/getVoucherList?pageNumber=${page}&pageSize=${rowPerPage}&search=${search}&paging=${true}`,
        {
          headers: { authorization: token },
        }
      );
      dispatch({ type: GET_ALL_PAYMENT_VOUCHERS_SUCCESS, payload: data });
    } catch (error) {
      if (error?.response?.status === 401) {
        SessionExpried(navigate, Dispatch);
      } else {
        dispatch({
          type: GET_ALL_PAYMENT_VOUCHERS_FAIL,
          payload: error.response?.data || "",
        });
      }
    }
  };

// Edit GL Entry
export const editVouvher = (row, type) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PAYMENT_VOUCHER_DETAILS_SUCCESS,
      payload: { row, type },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: EDIT_PAYMENT_VOUCHER_DETAILS_FAIL,
      payload: "",
    });
  }
};
// Get PaymentAccount of the base of type
export const getPaymentAccounts =
  (type, token, navigate, Dispatch) => async (dispatch) => {
    try {
      dispatch({ type: _GET_PAYMENT_ACCT_REQUEST });
      const { data } = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/paymentAcc/getPaymentACCListByPaymentType?paymentType=${type}`,
        headers: { authorization: token },
      });
      dispatch({ type: _GET_PAYMENT_ACCT_SUCCESS, payload: data });
    } catch (error) {
      if (error?.response?.status === 401) {
        SessionExpried(navigate, Dispatch);
      } else {
        dispatch({
          type: _GET_PAYMENT_ACCT_FAIL,
          payload: error.response?.data || "",
        });
      }
    }
  };
// Export Voucher As CSV
export const exportVouchers =
  (page, rowPerPage, search) => async (dispatch) => {
    try {
      dispatch({ type: EXPORT_PAYMENT_VOUCHER_REQUEST });
      const { data } = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/glEntry/GLExrtToCsv?pageNumber=${page}&pageSize=${rowPerPage}&search=${search}`,
      });

      dispatch({ type: EXPORT_PAYMENT_VOUCHER_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: EXPORT_PAYMENT_VOUCHER_FAIL,
        payload: error.response?.data || "",
      });
    }
  };

// Attachment SAVE
export const saveAttachments = fileData => async dispatch => {
  try {
    dispatch({ type: PAYMENT_ATTACHMENTS_REQUEST })

    dispatch({ type: PAYMENT_ATTACHMENTS_SUCCESS, payload: fileData })
  } catch (error) {

  }
}
// Attachment DELETE
export const AttachmentsDelete = fileIndex => async dispatch => {
  dispatch({ type: PAYMENT_ATTACHMENTS_DELETE, payload: fileIndex })
}
