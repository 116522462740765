//USER
export const SET_TOKEN = "SET_TOKEN";
export const SET_PERMISSIONS = "SET_PERMISSIONS";

// for Chart of Account
export const GET_ALL_COA_REQUEST = "GET_ALL_COA_REQUEST";
export const GET_ALL_COA_SUCCESS = "GET_ALL_COA_SUCCESS";
export const EXPORT_COA_REQUEST = "EXPORT_COA_REQUEST";
export const EXPORT_COA_SUCCESS = "EXPORT_COA_SUCCESS";
export const EXPORT_COA_FAIL = "EXPORT_COA_FAIL";
export const GET_ALL_COA_FAIL = "GET_ALL_COA_FAIL";

export const CLEAR_ALL_COA_FORM = "CLEAR_ALL_COA_FORM";

export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const SAVE_ACCOUNTS = "SAVE_ACCOUNTS";
export const FAIL_ACCOUNTS = "FAIL_ACCOUNTS";

export const GET_EXISTING_COA_LIST_REQUEST = "GET_EXISTING_COA_LIST_REQUEST";
export const GET_EXISTING_COA_LIST_SAVE = "GET_EXISTING_COA_LIST_SAVE";
export const GET_EXISTING_COA_LIST_FAIL = "GET_EXISTING_COA_LIST_FAIL";

export const GET_ACCOUNTS_BY_ID_REQUEST = "GET_ACCOUNTS_BY_ID_REQUEST";
export const GET_ACCOUNTS_BY_ID_SUCCESS = "GET_ACCOUNTS_BY_ID_SUCCESS";
export const GET_ACCOUNTS_BY_ID_FAIL = "GET_ACCOUNTS_BY_ID_FAIL";

export const GET_COA_BY_TYPE_REQUEST = "GET_COA_BY_TYPE_REQUEST";
export const GET_COA_BY_TYPE_SUCCESS = "GET_COA_BY_TYPE_SUCCESS";
export const GET_COA_BY_TYPE_FAIL = "GET_COA_BY_TYPE_FAIL";

export const GET_TAX_LIST = "GET_TAX_LIST";
export const SAVE_TAX_LIST = "SAVE_TAX_LIST";
export const FAIL_TAX_LIST = "FAIL_TAX_LIST";

export const ACCOUNT_DETAILS_REQUEST = "ACCOUNT_DETAILS_REQUEST";
export const ACCOUNT_DETAILS_SAVE = "ACCOUNT_DETAILS_SAVE";
export const SUBMIT_ACCOUNT_DETAILS_REQUEST = "SUBMIT_ACCOUNT_DETAILS_REQUEST";
export const SUBMIT_ACCOUNT_DETAILS_SUCCESS = "SUBMIT_ACCOUNT_DETAILS_SUCCESS";
export const SUBMIT_ACCOUNT_DETAILS_FAIL = "SUBMIT_ACCOUNT_DETAILS_FAIL";

// for GL Entry form
export const GL_DETAILS_REQUEST = "GL_DETAILS_REQUEST";
export const GL_DETAILS_SAVE = "GL_DETAILS_SAVE";
export const GL_LINES_DETAILS_REQUEST = "GL_LINES_DETAILS_REQUEST";
export const GL_LINES_DETAILS_SAVE = "GL_LINES_DETAILS_SAVE";
export const ADD_NEW_GL_LINE_SUCCESS = "ADD_NEW_GL_LINE_SUCCESS";
export const COPY_NARRATION = "COPY_NARRATION";
export const GET_GL_TAX_LIST_REQ = "GET_GL_TAX_LIST_REQ";
export const GET_GL_TAX_LIST_SUCCESS = "GET_GL_TAX_LIST_SUCCESS";
export const GET_GL_TAX_LIST_FAIL = "GET_GL_TAX_LIST_FAIL";
export const GET_COA_LIST_REQUEST = "GET_COA_LIST_REQUEST";
export const GET_COA_LIST_SAVE = "GET_COA_LIST_SAVE";
export const GET_COA_LIST_FAIL = "GET_COA_LIST_FAIL";
export const GET_CURRENCIES_REQUEST = "GET_CURRENCIES_REQUEST";
export const GET_CURRENCIES_SAVE = "GET_CURRENCIES_SAVE";
export const GET_CURRENCIES_FAIL = "GET_CURRENCIES_FAIL";
export const DEBIT_ITEMS_SUBMIT = "DEBIT_ITEMS_SUBMIT";
export const ADD_LINE_ITEMS = "ADD_LINE_ITEMS";
export const UPDATE_ITEMS_SUBMIT = "UPDATE_ITEMS_SUBMIT";
export const DEBIT_ITEMS_DELETE = "DEBIT_ITEMS_DELETE";
export const DEBIT_ITEMS_EDIT = "DEBIT_ITEMS_EDIT";
export const CREDIT_ITEMS_SUBMIT = "CREDIT_ITEMS_SUBMIT";
export const CREDIT_ITEMS_DELETE = "CREDIT_ITEMS_DELETE";
export const CREDIT_ITEMS_EDIT = "CREDIT_ITEMS_EDIT";
export const SUBMIT_GL_ENTRY_REQUEST = "SUBMIT_GL_ENTRY_REQUEST";
export const SUBMIT_GL_ENTRY_SUCCESS = "SUBMIT_GL_ENTRY_SUCCESS";
export const SUBMIT_GL_ENTRY_FAIL = "SUBMIT_GL_ENTRY_FAIL";
export const CLEAR_ALL_GL_FORM = "CLEAR_ALL_GL_FORM";
export const CLEAR_DEBIT_FORM = "CLEAR_DEBIT_FORM";
export const CLEAR_CREDIT_FORM = "CLEAR_CREDIT_FORM";
export const GET_ALL_GL_ENTRY_REQUEST = "GET_ALL_GL_ENTRY_REQUEST";
export const GET_ALL_GL_ENTRY_SUCCESS = "GET_ALL_GL_ENTRY_SUCCESS";
export const GET_ALL_GL_ENTRY_FAIL = "GET_ALL_GL_ENTRY_FAIL";
export const EDIT_GL_ENTRY_SUCCESS = "EDIT_GL_ENTRY_SUCCESS";
export const EDIT_GL_ENTRY_FAIL = "EDIT_GL_ENTRY_FAIL";
export const EXPORT_GL_ENTRY_REQUEST = "EXPORT_GL_ENTRY_REQUEST";
export const EXPORT_GL_ENTRY_SUCCESS = "EXPORT_GL_ENTRY_SUCCESS";
export const EXPORT_GL_ENTRY_FAIL = "EXPORT_GL_ENTRY_FAIL";
export const GL_ATTACHMENTS_REQUEST = "GL_ATTACHMENTS_REQUEST";
export const GL_ATTACHMENTS_SUCCESS = "GL_ATTACHMENTS_SUCCESS";
export const GL_ATTACHMENTS_DELETE = "GL_ATTACHMENTS_DELETE";
export const GL_VALIDATE_DATE_REQUEST = "GL_VALIDATE_DATE_REQUEST";
export const GL_VALIDATE_DATE_SUCCESS = "GL_VALIDATE_DATE_SUCCESS";
export const GL_VALIDATE_DATE_FAIL = "GL_VALIDATE_DATE_FAIL";

// for Account Payment Details
export const ACCT_DETAILS_REQUEST = "ACCT_DETAILS_REQUEST";
export const ACCT_DETAILS_SAVE = "ACCT_DETAILS_SAVE";
export const CLEAR_FORM = "CLEAR_FORM";
export const SUBMIT_PAYMENT_ACCOUNT_REQUEST = "SUBMIT_PAYMENT_ACCOUNT_REQUEST";
export const SUBMIT_PAYMENT_ACCOUNT_SUCCESS = "SUBMIT_PAYMENT_ACCOUNT_SUCCESS";
export const SUBMIT_PAYMENT_ACCOUNT_FAIL = "SUBMIT_PAYMENT_ACCOUNT_FAIL";
export const GET_ALL_PAYMENT_ACCT_REQUEST = "GET_ALL_PAYMENT_ACCT_REQUEST";
export const GET_ALL_PAYMENT_ACCT_SUCCESS = "GET_ALL_PAYMENT_ACCT_SUCCESS";
export const GET_ALL_PAYMENT_ACCT_FAIL = "GET_ALL_PAYMENT_ACCT_FAIL";
export const EDIT_ENTRY_SUCCESS = "EDIT_ENTRY_SUCCESS";
export const EDIT_ENTRY_FAIL = "EDIT_ENTRY_FAIL";
export const EXPORT_ACCOUNT_REQUEST = "EXPORT_ACCOUNT_REQUEST";
export const EXPORT_ACCOUNT_SUCCESS = "EXPORT_ACCOUNT_SUCCESS";
export const EXPORT_ACCOUNT_FAIL = "EXPORT_ACCOUNT_FAIL";

// for Deposit Voucher
export const VOUCHER_DETAILS_REQUEST = "VOUCHER_DETAILS_REQUEST";
export const VOUCHER_DETAILS_SAVE = "VOUCHER_DETAILS_SAVE";
export const VOUCHER_LINES_DETAILS_REQUEST = "VOUCHER_LINES_DETAILS_REQUEST";
export const VOUCHER_LINES_DETAILS_SAVE = "VOUCHER_LINES_DETAILS_SAVE";
export const ADD_LINE_SUCCESS = "ADD_LINE_SUCCESS";
export const VOUCHER_LINES_DELETE = "VOUCHER_LINES_DELETE";
export const CLEAR_ALL_VOUCHER_FORM = "CLEAR_ALL_VOUCHER_FORM";
export const CLEAR_LINE_ITEMS = "CLEAR_LINE_ITEMS";
export const LINE_ITEMS_SUBMIT = "LINE_ITEMS_SUBMIT";
export const LINE_ITEMS_DELETE = "LINE_ITEMS_DELETE";
export const LINE_ITEMS_EDIT = "LINE_ITEMS_EDIT";
export const UPDATE_LINE_ITEMS = "UPDATE_LINE_ITEMS";
export const GET_ALL_VOUCHERS_REQUEST = "GET_ALL_VOUCHERS_REQUEST";
export const GET_ALL_VOUCHERS_SUCCESS = "GET_ALL_VOUCHERS_SUCCESS";
export const GET_ALL_VOUCHERS_FAIL = "GET_ALL_VOUCHERS_FAIL";
export const _COA_LIST_REQUEST = "_COA_LIST_REQUEST";
export const _COA_LIST_SAVE = "_COA_LIST_SAVE";
export const _COA_LIST_FAIL = "_COA_LIST_FAIL";
export const EXPORT_VOUCHER_REQUEST = "EXPORT_VOUCHER_REQUEST";
export const EXPORT_VOUCHER_SUCCESS = "EXPORT_VOUCHER_SUCCESS";
export const EXPORT_VOUCHER_FAIL = "EXPORT_VOUCHER_FAIL";
export const SUBMIT_VOUCHER_REQUEST = "SUBMIT_VOUCHER_REQUEST";
export const SUBMIT_VOUCHER_SUCCESS = "SUBMIT_VOUCHER_SUCCESS";
export const SUBMIT_VOUCHER_FAIL = "SUBMIT_VOUCHER_FAIL";
export const EDIT_VOUCHER_DETAILS_SUCCESS = "EDIT_VOUCHER_DETAILS_SUCCESS";
export const EDIT_VOUCHER_DETAILS_FAIL = "EDIT_VOUCHER_DETAILS_FAIL";
export const GET_PAYMENT_ACCT_REQUEST = "GET_PAYMENT_ACCT_REQUEST";
export const GET_PAYMENT_ACCT_SUCCESS = "GET_PAYMENT_ACCT_SUCCESS";
export const GET_PAYMENT_ACCT_FAIL = "GET_PAYMENT_ACCT_FAIL";
export const DOWNLOAD_ENTRY_REQUEST = "DOWNLOAD_ENTRY_REQUEST";
export const DOWNLOAD_ENTRY_SUCCESS = "DOWNLOAD_ENTRY_SUCCESS";
export const DOWNLOAD_ENTRY_FAIL = "DOWNLOAD_ENTRY_FAIL";
export const CLEAR_DOWNLOAD_ENTRY = "CLEAR_DOWNLOAD_ENTRY";
export const VOUCHER_VALIDATE_DATE_REQUEST = "VOUCHER_VALIDATE_DATE_REQUEST";
export const VOUCHER_VALIDATE_DATE_SUCCESS = "VOUCHER_VALIDATE_DATE_SUCCESS";
export const VOUCHER_VALIDATE_DATE_FAIL = "VOUCHER_VALIDATE_DATE_FAIL";
export const DEPOSIT_ATTACHMENTS_REQUEST = "DEPOSIT_ATTACHMENTS_REQUEST";
export const DEPOSIT_ATTACHMENTS_SUCCESS = " DEPOSIT_ATTACHMENTS_SUCCESS";
export const DEPOSIT_ATTACHMENTS_DELETE = " DEPOSIT_ATTACHMENTS_DELETE";


// for Payment Voucher
export const PAYMENT_VOUCHER_DETAILS_REQUEST =
  "PAYMENT_VOUCHER_DETAILS_REQUEST";
export const PAYMENT_VOUCHER_DETAILS_SAVE = "PAYMENT_VOUCHER_DETAILS_SAVE";
export const PAYMENT_VOUCHER_LINES_DETAILS_REQUEST =
  "PAYMENT_VOUCHER_LINES_DETAILS_REQUEST";
export const PAYMENT_VOUCHER_LINES_DETAILS_SAVE =
  "PAYMENT_VOUCHER_LINES_DETAILS_SAVE";
export const ADD_NEW_LINE_SUCCESS = "ADD_NEW_LINE_SUCCESS";
export const LINES_DELETE = "LINES_DELETE";
export const LINES_UPDATE = "LINES_UPDATE";
export const CLEAR_ALL_PAYMENT_VOUCHER_FORM = "CLEAR_ALL_PAYMENT_VOUCHER_FORM";
export const PAYMENT_CLEAR_LINE_ITEMS = "PAYMENT_CLEAR_LINE_ITEMS";
export const PAYMENT_LINE_ITEMS_SUBMIT = "PAYMENT_LINE_ITEMS_SUBMIT";
export const PAYMENT_LINE_ITEMS_DELETE = "PAYMENT_LINE_ITEMS_DELETE";
export const PAYMENT_LINE_ITEMS_EDIT = "PAYMENT_LINE_ITEMS_EDIT";
export const GET_ALL_PAYMENT_VOUCHERS_REQUEST =
  "GET_ALL_PAYMENT_VOUCHERS_REQUEST";
export const GET_ALL_PAYMENT_VOUCHERS_SUCCESS =
  "GET_ALL_PAYMENT_VOUCHERS_SUCCESS";
export const GET_ALL_PAYMENT_VOUCHERS_FAIL = "GET_ALL_PAYMENT_VOUCHERS_FAIL";
export const COA_LIST_REQUEST = "COA_LIST_REQUEST";
export const COA_LIST_SAVE = "COA_LIST_SAVE";
export const COA_LIST_FAIL = "COA_LIST_FAIL";
export const EXPORT_PAYMENT_VOUCHER_REQUEST = "EXPORT_PAYMENT_VOUCHER_REQUEST";
export const EXPORT_PAYMENT_VOUCHER_SUCCESS = "EXPORT_PAYMENT_VOUCHER_SUCCESS";
export const EXPORT_PAYMENT_VOUCHER_FAIL = "EXPORT_PAYMENT_VOUCHER_FAIL";
export const SUBMIT_PAYMENT_VOUCHER_REQUEST = "SUBMIT_PAYMENT_VOUCHER_REQUEST";
export const SUBMIT_PAYMENT_VOUCHER_SUCCESS = "SUBMIT_PAYMENT_VOUCHER_SUCCESS";
export const SUBMIT_PAYMENT_VOUCHER_FAIL = "SUBMIT_PAYMENT_VOUCHER_FAIL";
export const EDIT_PAYMENT_VOUCHER_DETAILS_SUCCESS =
  "EDIT_PAYMENT_VOUCHER_DETAILS_SUCCESS";
export const EDIT_PAYMENT_VOUCHER_DETAILS_FAIL =
  "EDIT_PAYMENT_VOUCHER_DETAILS_FAIL";
export const _GET_PAYMENT_ACCT_REQUEST = "_GET_PAYMENT_ACCT_REQUEST";
export const _GET_PAYMENT_ACCT_SUCCESS = "_GET_PAYMENT_ACCT_SUCCESS";
export const _GET_PAYMENT_ACCT_FAIL = "_GET_PAYMENT_ACCT_FAIL";
export const DOWNLOAD_PAYMENT_VOUCHER_REQUEST =
  "DOWNLOAD_PAYMENT_VOUCHER_REQUEST";
export const DOWNLOAD_PAYMENT_VOUCHER_SUCCESS =
  "DOWNLOAD_PAYMENT_VOUCHER_SUCCESS";
export const DOWNLOAD_PAYMENT_VOUCHER_FAIL = "DOWNLOAD_PAYMENT_VOUCHER_FAIL";
export const CLEAR_DOWNLOAD_PAYMENT_VOUCHER = "CLEAR_DOWNLOAD_PAYMENT_VOUCHER";
export const PAYMENT_VOUCHER_VALIDATE_DATE_REQUEST =
  "PAYMENT_VOUCHER_VALIDATE_DATE_REQUEST";
export const PAYMENT_VOUCHER_VALIDATE_DATE_SUCCESS =
  "PAYMENT_VOUCHER_VALIDATE_DATE_SUCCESS";
export const PAYMENT_VOUCHER_VALIDATE_DATE_FAIL =
  "PAYMENT_VOUCHER_VALIDATE_DATE_FAIL";
export const PAYMENT_ATTACHMENTS_REQUEST = "PAYMENT_ATTACHMENTS_REQUEST";
export const PAYMENT_ATTACHMENTS_SUCCESS = " PAYMENT_ATTACHMENTS_SUCCESS";
export const PAYMENT_ATTACHMENTS_DELETE = " PAYMENT_ATTACHMENTS_DELETE";

// FOR GENERAL SETUP
export const GS_ACCOUNT_SETUP_REQUEST = "GS_ACCOUNT_SETUP_REQUEST";
export const GS_ACCOUNT_SETUP_SUCCESS = "GS_ACCOUNT_SETUP_SUCCESS";
export const SAVE_ACCOUNT_CODES_REQUEST = "SAVE_ACCOUNT_CODES_REQUEST";
export const SAVE_ACCOUNT_CODES_SUCCESS = "SAVE_ACCOUNT_CODES_SUCCESS";
export const CLEAR_GS_ACCOUNT_SETUP = "CLEAR_GS_ACCOUNT_SETUP";
export const CLEAR_GS_STATE = "CLEAR_GS_STATE";
export const GET_GENERAL_CONFIG_REQUEST = "GET_GENERAL_CONFIG_REQUEST";
export const GET_GENERAL_CONFIG_SUCCESS = "GET_GENERAL_CONFIG_SUCCESS";
export const GET_GENERAL_CONFIG_FAIL = "GET_GENERAL_CONFIG_FAIL";
export const UPDATE_GENERAL_CONFIG_REQUEST = "UPDATE_GENERAL_CONFIG_REQUEST";
export const UPDATE_GENERAL_CONFIG_SUCCESS = "UPDATE_GENERAL_CONFIG_SUCCESS";
export const UPDATE_GENERAL_CONFIG_FAIL = "UPDATE_GENERAL_CONFIG_FAIL";

// for Add New Sub Account
export const CREATE_SUB_ACCOUNT_REQUEST = "CREATE_SUB_ACCOUNT_REQUEST";
export const CREATE_SUB_ACCOUNT_SUCCESS = "CREATE_SUB_ACCOUNT_SUCCESS";
export const CLEAR_SUB_ACCOUNT_FORM = "CLEAR_SUB_ACCOUNT_FORM";
export const SUBMIT_SUB_ACCOUNT_DETAILS_REQUEST =
  "SUBMIT_SUB_ACCOUNT_DETAILS_REQUEST";
export const SUBMIT_SUB_ACCOUNT_DETAILS_SUCCESS =
  "SUBMIT_SUB_ACCOUNT_DETAILS_SUCCESS";
export const SUBMIT_SUB_ACCOUNT_DETAILS_FAIL =
  "SUBMIT_SUB_ACCOUNT_DETAILS_FAIL";
export const GET_ALL_SUB_ACCOUNTS_REQUEST = "GET_ALL_SUB_ACCOUNTS_REQUEST";
export const GET_ALL_SUB_ACCOUNTS_SUCCESS = "GET_ALL_SUB_ACCOUNTS_SUCCESS";
export const GET_ALL_SUB_ACCOUNTS_FAIL = "GET_ALL_SUB_ACCOUNTS_FAIL";
export const GET_ALL_ACCOUNTS_TYPES_REQUEST = "GET_ALL_ACCOUNTS_TYPES_REQUEST";
export const GET_ALL_ACCOUNTS_TYPES_SUCCESS = "GET_ALL_ACCOUNTS_TYPES_SUCCESS";
export const GET_ALL_ACCOUNTS_TYPES_FAIL = "GET_ALL_ACCOUNTS_TYPES_FAIL";
export const EDIT_ACCOUNT_SUCCESS = "EDIT_ACCOUNT_SUCCESS";
export const EDIT_ACCOUNT_FAIL = "EDIT_ACCOUNT_FAIL";
export const GET_EXISTING_SUB_ACCOUNTS_REQUEST = "GET_EXISTING_SUB_ACCOUNTS_REQUEST";
export const GET_EXISTING_SUB_ACCOUNTS_SUCCESS = "GET_EXISTING_SUB_ACCOUNTS_SUCCESS";
export const GET_EXISTING_SUB_ACCOUNTS_FAIL = "GET_EXISTING_SUB_ACCOUNTS_FAIL";

// for Add New Tax
export const CREATE_TAX_REQUEST = "CREATE_TAX_REQUEST";
export const CREATE_TAX_SUCCESS = "CREATE_TAX_SUCCESS";
export const CREATE_TAX_ROW_REQUEST = "CREATE_TAX_ROW_REQUEST";
export const CREATE_TAX_ROW_SUCCESS = "CREATE_TAX_ROW_SUCCESS";
export const ADD_NEW_TAX_ROW_REQUEST = "ADD_NEW_TAX_ROW_REQUEST";
export const ADD_NEW_TAX_ROW_SUCCESS = "ADD_NEW_TAX_ROW_SUCCESS";
export const ADD_NEW_TAX_ROW_FAIL = "ADD_NEW_TAX_ROW_FAIL";
export const DELETE_TAX_ROW_REQUEST = "DELETE_TAX_ROW_REQUEST";
export const DELETE_TAX_ROW_SUCCESS = "DELETE_TAX_ROW_SUCCESS";
export const DELETE_TAX_ROW_FAIL = "DELETE_TAX_ROW_FAIL";
export const CLEAR_TAX_FORM = "CLEAR_TAX_FORM";
export const SUBMIT_TAX_DETAILS_REQUEST = "SUBMIT_TAX_DETAILS_REQUEST";
export const SUBMIT_TAX_DETAILS_SUCCESS = "SUBMIT_TAX_DETAILS_SUCCESS";
export const SUBMIT_TAX_DETAILS_FAIL = "SUBMIT_TAX_DETAILS_FAIL";
export const GET_ALL_TAX_REQUEST = "GET_ALL_TAX_REQUEST";
export const GET_ALL_TAX_SUCCESS = "GET_ALL_TAX_SUCCESS";
export const GET_ALL_TAX_FAIL = "GET_ALL_TAX_FAIL";
export const GET_ALL_COA_ACCT_REQUEST = "GET_ALL_COA_ACCT_REQUEST";
export const GET_ALL_COA_ACCT_SUCCESS = "GET_ALL_COA_ACCT_SUCCESS";
export const GET_ALL_COA_ACCT_FAIL = "GET_ALL_COA_ACCT_FAIL";
export const EDIT_TAX_SUCCESS = "EDIT_TAX_SUCCESS";
export const EDIT_TAX_FAIL = "EDIT_TAX_FAIL";

// for Financial Setting
export const FINANCIAL_YEAR_REQUEST = "FINANCIAL_YEAR_REQUEST";
export const FINANCIAL_YEAR_SUCCESS = "FINANCIAL_YEAR_SUCCESS";
export const FINANCIAL_YEAR_CLEAR_FORM = "FINANCIAL_YEAR_CLEAR_FORM";
export const GET_FINANCIAL_YEAR_REQUEST = "GET_FINANCIAL_YEAR_REQUEST";
export const GET_FINANCIAL_YEAR_SUCCESS = "GET_FINANCIAL_YEAR_SUCCESS";
export const GET_FINANCIAL_YEAR_FAIL = "GET_FINANCIAL_YEAR_FAIL";
export const FINANCIAL_YEAR_STATUS = "FINANCIAL_YEAR_STATUS";
export const SUBMIT_FINANCIAL_YEAR_REQUEST = "SUBMIT_FINANCIAL_YEAR_REQUEST";
export const SUBMIT_FINANCIAL_YEAR_SUCCESS = "SUBMIT_FINANCIAL_YEAR_SUCCESS";
export const SUBMIT_FINANCIAL_YEAR_FAIL = "SUBMIT_FINANCIAL_YEAR_FAIL";
export const FINANCIAL_YEAR_CLEAR_SUBMIT_FORM =
  "FINANCIAL_YEAR_CLEAR_SUBMIT_FORM";

// for General Ledger Enrty
export const FILTER_GL_ENTRY_REQUEST = "FILTER_GL_ENTRY_REQUEST";
export const FILTER_GL_ENTRY_SUCCESS = "FILTER_GL_ENTRY_SUCCESS";
export const CLEAR_FILTER_GL_ENTRY = "CLEAR_FILTER_GL_ENTRY";
export const CLEAR_GL_ENTRY = "CLEAR_GL_ENTRY";
export const SUBMIT_FILTER_GL_ENTRY_REQUEST = "SUBMIT_FILTER_GL_ENTRY_REQUEST";
export const SUBMIT_FILTER_GL_ENTRY_SUCCESS = "SUBMIT_FILTER_GL_ENTRY_SUCCESS";
export const SUBMIT_FILTER_GL_ENTRY_FAIL = "SUBMIT_FILTER_GL_ENTRY_FAIL";

// for Sales Transactions
export const SET_SALE_TXN_FILTERS_REQUEST = "SET_SALE_TXN_FILTERS_REQUEST";
export const SET_SALE_TXN_FILTERS_SUCCESS = "SET_SALE_TXN_FILTERS_SUCCESS";
export const CHANGE_SALE_TXN_FILTERS_REQUEST =
  "CHANGE_SALE_TXN_FILTERS_REQUEST";
export const CHANGE_SALE_TXN_FILTERS_SUCCESS =
  "CHANGE_SALE_TXN_FILTERS_SUCCESS";
export const CLEAR_SALE_TXN_FILTER_GL_ENTRY = "CLEAR_SALE_TXN_FILTER_GL_ENTRY";
export const CLEAR_SALE_TXN_REPORT = "CLEAR_SALE_TXN_REPORT";
export const GET_SALE_TXN_REPORT_REQUEST = "GET_SALE_TXN_REPORT_REQUEST";
export const GET_SALE_TXN_REPORT_SUCCESS = "SET_SALE_TXN_REPORT_SUCCESS";
export const GET_SALE_TXN_REPORT_FAIL = "SET_SALE_TXN_REPORT_FAIL";
export const GET__COA_ACCT_REQUEST = "GET__COA_ACCT_REQUEST";
export const GET__COA_ACCT_SUCCESS = "GET__COA_ACCT_SUCCESS";
export const GET__COA_ACCT_FAIL = "GET__COA_ACCT_FAIL";

// for Trail Balance
export const TRAIL_BAL_FILTERS_REQUEST = "SET_TRAIL_BAL_FILTERS_REQUEST";
export const TRAIL_BAL_FILTERS_SUCCESS = "SET_TRAIL_BAL_FILTERS_SUCCESS";
export const GET_DATE_BY_TYPE_REQUEST = "SET_GET_DATE_BY_TYPE_REQUEST";
export const GET_DATE_BY_TYPE_SUCCESS = "SET_GET_DATE_BY_TYPE_SUCCESS";
export const GET_DATE_BY_TYPE_FAIL = "SET_GET_DATE_BY_TYPE_FAIL";
export const GET_TRAIL_BAL_REPORT_REQUEST = "GET_TRAIL_BAL_REPORT_REQUEST";
export const GET_TRAIL_BAL_REPORT_SUCCESS = "GET_TRAIL_BAL_REPORT_SUCCESS";
export const GET_TRAIL_BAL_REPORT_FAIL = "GET_TRAIL_BAL_REPORT_FAIL";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const CLEAR_ALL_DATA = "CLEAR_ALL_DATA";
export const SET_REPORT_EMPTY = "SET_REPORT_EMPTY";
export const GET_PREVIOUS_DATES_REQUEST = "GET_PREVIOUS_DATES_REQUEST";
export const GET_PREVIOUS_DATES_SUCCESS = "GET_PREVIOUS_DATES_SUCCESS";
export const GET_PREVIOUS_DATES_FAIL = "GET_PREVIOUS_DATES_FAIL";

// for Balance Sheet(BS)
export const BAL_SHEET_FILTERS_REQUEST = "SET_BAL_SHEET_FILTERS_REQUEST";
export const BAL_SHEET_FILTERS_SUCCESS = "SET_BAL_SHEET_FILTERS_SUCCESS";
export const GET_DATE_BY_TYPE_BS_REQUEST = "GET_DATE_BY_TYPE_BS_REQUEST";
export const GET_DATE_BY_TYPE_BS_SUCCESS = "GET_DATE_BY_TYPE_BS_SUCCESS";
export const GET_DATE_BY_TYPE_BS_FAIL = "SET_GET_DATE_BY_TYPE_BS_FAIL";
export const GET_BAL_SHEET_REPORT_REQUEST = "GET_BAL_SHEET_REPORT_REQUEST";
export const GET_BAL_SHEET_REPORT_SUCCESS = "GET_BAL_SHEET_REPORT_SUCCESS";
export const GET_BAL_SHEET_REPORT_FAIL = "GET_BAL_SHEET_REPORT_FAIL";
export const CLEAR_BAL_SHEET_REPORT = "CLEAR_BAL_SHEET_REPORT";
export const CLEAR_FILTERS_BS = "CLEAR_FILTERS_BS";
export const CLEAR_ALL_DATA_BS = "CLEAR_ALL_DATA_BS";
export const SET_REPORT_EMPTY_BS = "SET_REPORT_EMPTY";
export const GET_PREVIOUS_DATES_REQUEST_BS = "GET_PREVIOUS_DATES_REQUEST_BS";
export const GET_PREVIOUS_DATES_SUCCESS_BS = "GET_PREVIOUS_DATES_SUCCESS_BS";
export const GET_PREVIOUS_DATES_FAIL_BS = "GET_PREVIOUS_DATES_FAIL_BS";

// for Profit Loss(PR)
export const PROFITLOSS_FILTERS_REQUEST = "SET_PROFITLOSS_FILTERS_REQUEST";
export const PROFITLOSS_FILTERS_SUCCESS = "SET_PROFITLOSS_FILTERS_SUCCESS";
export const GET_DATE_BY_TYPE_PR_REQUEST = "GET_DATE_BY_TYPE_PR_REQUEST";
export const GET_DATE_BY_TYPE_PR_SUCCESS = "GET_DATE_BY_TYPE_PR_SUCCESS";
export const GET_DATE_BY_TYPE_PR_FAIL = "GET_DATE_BY_TYPE_PR_FAIL";
export const GET_PREVIOUS_DATES_PR_REQUEST = "GET_PREVIOUS_DATES_PR_REQUEST";
export const GET_PREVIOUS_DATES_PR_SUCCESS = "GET_PREVIOUS_DATES_PR_SUCCESS";
export const GET_PREVIOUS_DATES_PR_FAIL = "GET_PREVIOUS_DATES_PR_FAIL";
export const PROFITLOSS_CLEAR_FILTERS = "PROFITLOSS_CLEAR_FILTERS";
export const PROFITLOSS_CLEAR_ALL_DATA = "PROFITLOSS_CLEAR_ALL_DATA";
export const GET_PROFIT_LOSS_REPORT_REQUEST = "GET_PROFIT_LOSS_REPORT_REQUEST";
export const GET_PROFIT_LOSS_REPORT_SUCCESS = "GET_PROFIT_LOSS_REPORT_SUCCESS";
export const GET_PROFIT_LOSS_REPORT_FAIL = "GET_PROFIT_LOSS_REPORT_FAIL";
export const CLEAR_PROFIT_LOSS_REPORT = "CLEAR_PROFIT_LOSS_REPORT";

// for CASH FLOW STATEMENT
export const CASHFLOW_FILTERS_REQUEST = "CASHFLOW_FILTERS_REQUEST";
export const CASHFLOW_FILTERS_SUCCESS = "CASHFLOW_FILTERS_SUCCESS";
export const GET_DATE_BY_TYPE_CF_REQUEST = "GET_DATE_BY_TYPE_CF_REQUEST";
export const GET_DATE_BY_TYPE_CF_SUCCESS = "GET_DATE_BY_TYPE_CF_SUCCESS";
export const GET_DATE_BY_TYPE_CF_FAIL = "GET_DATE_BY_TYPE_CF_FAIL";
export const GET_PREVIOUS_DATES_CF_REQUEST = "GET_PREVIOUS_DATES_CF_REQUEST";
export const GET_PREVIOUS_DATES_CF_SUCCESS = "GET_PREVIOUS_DATES_CF_SUCCESS";
export const GET_PREVIOUS_DATES_CF_FAIL = "GET_PREVIOUS_DATES_CF_FAIL";
export const CASHFLOW_CLEAR_FILTERS = "CASHFLOW_CLEAR_FILTERS";
export const CASHFLOW_CLEAR_ALL_DATA = "CASHFLOW_CLEAR_ALL_DATA";
export const GET_CASHFLOW_REPORT_REQUEST = "GET_CASHFLOW_REPORT_REQUEST";
export const GET_CASHFLOW_REPORT_SUCCESS = "GET_CASHFLOW_REPORT_SUCCESS";
export const GET_CASHFLOW_REPORT_FAIL = "GET_CASHFLOW_REPORT_FAIL";
export const CLEAR_CASHFLOW_REPORT = "CLEAR_CASHFLOW_REPORT";
export const GET_LIST_OF_ACCT_CF_REQUEST = "GET_LIST_OF_ACCT_CF_REQUEST";
export const GET_LIST_OF_ACCT_CF_SUCCESS = "GET_LIST_OF_ACCT_CF_SUCCESS";
export const GET_LIST_OF_ACCT_CF_FAIL = "GET_LIST_OF_ACCT_CF_FAIL";

// for GL Inquiry Form
export const SET_GL_INQUIRY_FILTER_REQUEST = "SET_GL_INQUIRY_FILTER_REQUEST";
export const SET_GL_INQUIRY_FILTER_SUCCESS = "SET_GL_INQUIRY_FILTER_SUCCESS";
export const GL_INQUIRY_REQUEST = "GL_INQUIRY_REQUEST";
export const GL_INQUIRY_SUCCESS = "GL_INQUIRY_SUCCESS";
export const GL_INQUIRY_FAIL = "GL_INQUIRY_FAIL";
export const POST_GL_ENTRY_REQUEST = "GL_ENTRY_REQUEST";
export const POST_GL_ENTRY_SUCCESS = "GL_ENTRY_SUCCESS";
export const POST_GL_ENTRY_FAIL = "GL_ENTRY_FAIL";
export const CLEAR_ALL_GL_INQUIRY = "CLEAR_ALL_GL_INQUIRY";
export const CLEAR_SUCCESS_GL_INQUIRY = "CLEAR_SUCCESS_GL_INQUIRY";

// GET ALL GENRAL KPIS
export const GET_GENRAL_KPIS_REQUEST = "GET_GENRAL_KPIS_REQUEST";
export const GET_GENRAL_KPIS_SUCCESS = "GET_GENRAL_KPIS_SUCCESS";
export const GET_GENRAL_KPIS_FAILED = "GET_GENRAL_KPIS_FAILED";

// PaymentVoucher In Dashboard
export const GET_PAYMENT_VOUCHER_FOR_DASHBOARD_REQUEST =
  "GET_PAYMENT_VOUCHER_FOR_DASHBOARD_REQUEST";
export const GET_PAYMENT_VOUCHER_FOR_DASHBOARD_SUCCESS =
  "GET_PAYMENT_VOUCHER_FOR_DASHBOARD_SUCCESS";
export const GET_PAYMENT_VOUCHER_FOR_DASHBOARD_FAILED =
  "GET_PAYMENT_VOUCHER_FOR_DASHBOARD_FAILED";

// Deposit Voucher In Dashboard
export const GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_REQUEST =
  "GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_REQUEST";
export const GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_SUCCESS =
  "GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_SUCCESS";
export const GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_FAILED =
  "GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_FAILED";

// get Profit Loss dates FOR DASHBOARD
export const GET_PROFIT_LOSS_DATES_REQUEST = "GET_PROFIT_LOSS_DATES_REQUEST ";
export const GET_PROFIT_LOSS_DATES_SUCCESS = "GET_PROFIT_LOSS_DATES_SUCCESS ";
export const GET_PROFIT_LOSS_DATES_FAILED = "GET_PROFIT_LOSS_DATES_FAILED ";
// Get CHART FOR ACC FOR DASHBOARD
export const GET_CHART_FOR_ACCOUNT_REQUEST = "GET_CHART_FOR_ACCOUNT_REQUEST ";
export const GET_CHART_FOR_ACCOUNT_SUCCESS = "GET_CHART_FOR_ACCOUNT_SUCCESS ";
export const GET_CHART_FOR_ACCOUNT_FAIL = "GET_CHART_FOR_ACCOUNT_FAIL ";
// Get PROFIT LOSS FOR DASHBOARD
export const GET_PROFIT_LOSS_REPORT_DASHBOARD_REQUEST =
  "GET_PROFIT_LOSS_REPORT_DASHBOARD_REQUEST ";
export const GET_PROFIT_LOSS_REPORT_DASHBOARD_SUCCESS =
  "GET_PROFIT_LOSS_REPORT_DASHBOARD_SUCCESS ";
export const GET_PROFIT_LOSS_REPORT_DASHBOARD_FAIL =
  "GET_PROFIT_LOSS_REPORT_DASHBOARD_FAIL ";
// filters FOR DASHBOARD
export const SET_DASHBOARD_FILTERS = "SET_DASHBOARD_FILTERS";
export const SET_DASHBOARD_PERIOD = "SET_DASHBOARD_PERIOD";
// -RESET FOR DASHBOARD
export const RESET_FILTERS = "RESET_FILTERS";

// EXPORT TO EXCEL
export const EXPORT_REQUEST = "EXPORT_REQUEST";
export const EXPORT_SUCCESS = "EXPORT_SUCCESS";
export const EXPORT_FAIL = "EXPORT_FAIL";
export const RESET_EXPORT_FIELDS = "RESET_EXPORT_FIELDS";
