import jwt from "jsonwebtoken";
import { SET_PERMISSIONS, SET_TOKEN } from "./Constant";


export const setToken = (tk) => async dispatch => {
  let data = {
    token: tk,
    userData: jwt.decode(tk)
  };
  try {
    dispatch({ type: SET_TOKEN, payload: data })
  } catch (error) {
    console.log(error)
  }
};

export const setPermissions = (permissions) => async dispatch =>  {
  try {
    dispatch({ type: SET_PERMISSIONS, payload: permissions })
  } catch (error) {
    console.log(error)
  }
};