import moment from 'moment'
import {
  SET_GL_INQUIRY_FILTER_REQUEST,
  SET_GL_INQUIRY_FILTER_SUCCESS,
  GL_INQUIRY_REQUEST,
  GL_INQUIRY_SUCCESS,
  GL_INQUIRY_FAIL,
  POST_GL_ENTRY_REQUEST,
  POST_GL_ENTRY_FAIL,
  POST_GL_ENTRY_SUCCESS,
  CLEAR_ALL_GL_INQUIRY,
  CLEAR_SUCCESS_GL_INQUIRY
} from '../Actions/Constant'

let initialState = {
  source: '',
  status: '',
  startDate: moment(new Date()).format('YYYY-MM-DD'),
  endDate: moment(new Date()).format('YYYY-MM-DD'),
  refrence: '',
  InquiryList: [],
  loading: false,
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: '',
  postLoading: false,
  postSuccess: false,
  postError: false,
  postErrorMsg: '',

}

export function GLIquiryReducer(state = initialState, action) {
  switch (action.type) {


    //  CLEAR ALL GL INQUIRY
    case CLEAR_ALL_GL_INQUIRY:
      return {
        source: '',
        status: '',
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        refrence: '',
        InquiryList: [],
        loading: false,
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: '',
        postLoading: false,
        postSuccess: false,
        postError: false,
        postErrorMsg: '',
      }

    //  CLEAR ALL GL INQUIRY AFTER SUCCESS
    case CLEAR_SUCCESS_GL_INQUIRY:
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        postError: false,
        postErrorMsg: '',
      }

    //  Save New Account Details
    case SET_GL_INQUIRY_FILTER_REQUEST:
      return { ...state }
    case SET_GL_INQUIRY_FILTER_SUCCESS:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    // getting all Data 
    case GL_INQUIRY_REQUEST:
      return {
        ...state,
        loading: true,
        inProgress: true,
        isSuccess: false,
        error: false,
        errorMsg: '',
        InquiryList: []
      }
    case GL_INQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        inProgress: false,
        isSuccess: true,
        error: false,
        errorMsg: '',
        InquiryList: action.payload
      }
    case GL_INQUIRY_FAIL:
      return {
        ...state,
        loading: true,
        inProgress: true,
        isSuccess: false,
        error: false,
        errorMsg: action.payload,
        InquiryList: []
      }
    // POST GL ENTRY
    case POST_GL_ENTRY_REQUEST:
      return {
        ...state,
        postLoading: true,
        postSuccess: false,
        postError: false,
        postErrorMsg: '',
      }
    case POST_GL_ENTRY_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postSuccess: true,
        postError: false,
        postErrorMsg: '',
      }
    case POST_GL_ENTRY_FAIL:
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        postError: true,
        postErrorMsg: action.payload,
      }
    default:
      return state
  }
}
