import { CallToActionSharp } from '@material-ui/icons'
import moment from 'moment'
import {
  PROFITLOSS_FILTERS_REQUEST,
  PROFITLOSS_FILTERS_SUCCESS,
  GET_DATE_BY_TYPE_PR_REQUEST,
  GET_DATE_BY_TYPE_PR_SUCCESS,
  GET_DATE_BY_TYPE_PR_FAIL,
  PROFITLOSS_CLEAR_FILTERS,
  PROFITLOSS_CLEAR_ALL_DATA,
  GET_PROFIT_LOSS_REPORT_REQUEST,
  GET_PROFIT_LOSS_REPORT_SUCCESS,
  GET_PROFIT_LOSS_REPORT_FAIL,
  GET_PREVIOUS_DATES_PR_SUCCESS,
  CLEAR_PROFIT_LOSS_REPORT,
} from '../Actions/Constant'

let initialState = {
  filter: {
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    dateWithFilter: '',
    compareWith: '1',
    period: 'year',
  },
  report: [],
  previousDate: [],
  credit: '',
  debit: '',
  loading: false,
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: ''
}

export function ProfitLossReducer(state = initialState, action) {
  switch (action.type) {
    // set filter
    case PROFITLOSS_FILTERS_REQUEST:
      return { ...state }
    case PROFITLOSS_FILTERS_SUCCESS:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.name]: action.payload.value
        }
      }
    case PROFITLOSS_CLEAR_FILTERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          startDate: moment(new Date()).format('YYYY-MM-DD'),
          endDate: moment(new Date()).format('YYYY-MM-DD'),
          dateWithFilter: '',
          compareWith: '1',
          period: 'year',
        },
        report: [],
        previousDate: [],
        credit: '',
        debit: '',
      }
    case GET_DATE_BY_TYPE_PR_SUCCESS:
      const { startDate, endDate } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          startDate: startDate,
          endDate: endDate,
        }
      }
    case GET_PREVIOUS_DATES_PR_SUCCESS:
      return {
        ...state,
        previousDate: action.payload.data
      }
    case GET_PROFIT_LOSS_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        inProgress: true,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }
    case GET_PROFIT_LOSS_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        inProgress: false,
        isSuccess: true,
        report: action.payload.data,
        error: false,
        errorMsg: ''
      }
    case GET_PROFIT_LOSS_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        inProgress: false,
        isSuccess: false,
        report: [],
        previousDate: [],
        credit: '',
        debit: '',
        error: true,
        errorMsg: action.payload
      }
    case PROFITLOSS_CLEAR_ALL_DATA:
      return {
        filter: {
          startDate: moment(new Date()).format('YYYY-MM-DD'),
          endDate: moment(new Date()).format('YYYY-MM-DD'),
          dateWithFilter: '',
          compareWith: '1',
          period: 'year',
        },
        report: [],
        previousDate: [],
        credit: '',
        debit: '',
        loading: false,
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }
      case CLEAR_PROFIT_LOSS_REPORT:
        return {
          ...state,
          report: [],
        }
    default:
      return state
  }
}
