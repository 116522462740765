import moment from 'moment'
import {
  BAL_SHEET_FILTERS_REQUEST,
  BAL_SHEET_FILTERS_SUCCESS,
  GET_DATE_BY_TYPE_BS_REQUEST,
  GET_DATE_BY_TYPE_BS_SUCCESS,
  GET_DATE_BY_TYPE_BS_FAIL,
  GET_BAL_SHEET_REPORT_REQUEST,
  GET_BAL_SHEET_REPORT_SUCCESS,
  GET_BAL_SHEET_REPORT_FAIL,
  CLEAR_FILTERS_BS,
  CLEAR_ALL_DATA_BS,
  SET_REPORT_EMPTY_BS,
  GET_PREVIOUS_DATES_REQUEST_BS,
  GET_PREVIOUS_DATES_SUCCESS_BS,
  GET_PREVIOUS_DATES_FAIL_BS,
  CLEAR_BAL_SHEET_REPORT,
} from '../Actions/Constant'

let initialState = {
  filter: {
    date: moment(new Date()).format('YYYY-MM-DD'),
    dateWithFilter: '',
    compareWith: '1',
    period: 'month',
    grouping: '',
    groupingType: 'none',
  },
  report: [],
  previousDates: [],
  credit: '',
  debit: '',
  loading: false,
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: ''
}

export function BalanceSheet(state = initialState, action) {
  switch (action.type) {
    // set filter
    case BAL_SHEET_FILTERS_REQUEST:
      return { ...state }
    case BAL_SHEET_FILTERS_SUCCESS:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.name]: action.payload.value
        }
      }
    case SET_REPORT_EMPTY_BS:
      return {
        ...state,
        report: []
      }
    case CLEAR_FILTERS_BS:
      return {
        ...state,
        filter: {
          ...state.filter,
          date: moment(new Date()).format('YYYY-MM-DD'),
          dateWithFilter: '',
          compareWith: '1',
          period: 'month',
          grouping: '',
          groupingType: 'none',
        },
        report: [],
        previousDates: [],
        credit: '',
        debit: '',
      }
    case GET_DATE_BY_TYPE_BS_SUCCESS:
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.payload
        }
      }
    case GET_PREVIOUS_DATES_SUCCESS_BS:
      return {
        ...state,
        previousDates: action.payload
      }
    case GET_BAL_SHEET_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        inProgress: true,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }
    case GET_BAL_SHEET_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        inProgress: false,
        isSuccess: true,
        report: action.payload.data,
        credit: action.payload.credit,
        debit: action.payload.debit,
        error: false,
        errorMsg: ''
      }
    case GET_BAL_SHEET_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        inProgress: false,
        isSuccess: false,
        report: [],
        credit: '',
        debit: '',
        error: true,
        errorMsg: action.payload
      }
    case CLEAR_ALL_DATA_BS:
      return {
        filter: {
          date: moment(new Date()).format('YYYY-MM-DD'),
          dateWithFilter: '',
          compareWith: '1',
          period: 'month',
          grouping: '',
          groupingType: 'none',
        },
        report: [],
        previousDates: [],
        credit: '',
        debit: '',
        loading: false,
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }
    case CLEAR_BAL_SHEET_REPORT:
      return {
        ...state,
        report: [],
      }
    default:
      return state
  }
}
