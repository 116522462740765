import { CallToActionSharp } from '@material-ui/icons'
import {
  CLEAR_SUB_ACCOUNT_FORM,
  CREATE_SUB_ACCOUNT_REQUEST,
  CREATE_SUB_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT_SUCCESS,
  GET_ALL_ACCOUNTS_TYPES_SUCCESS,
  GET_ALL_SUB_ACCOUNTS_SUCCESS,
  GET_EXISTING_SUB_ACCOUNTS_SUCCESS,
  SUBMIT_SUB_ACCOUNT_DETAILS_FAIL,
  SUBMIT_SUB_ACCOUNT_DETAILS_REQUEST,
  SUBMIT_SUB_ACCOUNT_DETAILS_SUCCESS
} from '../Actions/Constant'

let initialState = {
  accountDetails: {
    AccParentId: '',
    name: '',
    description: '',
    code: '',
    fullCode: '',
    AccParentName: '',
    parentAccountName: "",
    parentAccount: "",
    create: true,
    view: false,
    edit: false
  },
  CSV_Success: false,
  CSV_Url: '',
  loading: false,
  accounts: [],
  accountsList: [],
  controlAccounts: [],
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: ''
}

export function CreateSubAccount(state = initialState, action) {
  switch (action.type) {
    // clear All
    case CLEAR_SUB_ACCOUNT_FORM:
      return {
        ...state,
        accountDetails: {
          AccParentId: '',
          name: '',
          description: '',
          code: '',
          fullCode: '',
          AccParentName: '',
          parentAccountName: "",
          parentAccount: "",
          create: true,
          view: false,
          edit: false
        },
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }
    // getting all COA

    // case GET_ALL_COA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     inProgress: true,
    //     error: false,
    //     coaList: []
    //   }
    // case GET_ALL_COA_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     inProgress: false,
    //     isSuccess: true,
    //     error: false,
    //     coaList: action.payload
    //   }
    // case GET_ALL_COA_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: true,
    //     inProgress: false,
    //     isSuccess: false,
    //     coaList: [],
    //     errorMsg: action.payload
    //   }

    //  Save New Account Details
    case CREATE_SUB_ACCOUNT_REQUEST:
      return { ...state }
    case CREATE_SUB_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          [action.payload.name]: action.payload.value
        }
      }

    //  Get Account Types
    case GET_ALL_ACCOUNTS_TYPES_SUCCESS:
      return { ...state, accounts: action.payload }

    //  Get ALL EXTING CONTROL ACCOUNTS
    case GET_EXISTING_SUB_ACCOUNTS_SUCCESS:
      return { ...state, controlAccounts: action.payload }

    // Edit Account
    case EDIT_ACCOUNT_SUCCESS:
      const { type } = action.payload
      const {
        AccParentId,
        name,
        AccParentName,
        description,
        code,
        fullCode,
        parentAccount,
        parentAccountName,
        acctualCode,
        create,
        view,
        _id,
        edit } = action.payload.row
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          AccParentId: AccParentId || '',
          name: name || '',
          AccParentName: AccParentName,
          parentAccountName: parentAccountName,
          parentAccount: parentAccount,
          description: description,
          code: acctualCode,
          fullCode: acctualCode,
          id: _id,
          create: type === 'view' || type === 'edit' ? false : true,
          view: type === 'view' ? true : false,
          edit: type === 'edit' ? true : false
        }
      }

    // export to CSV 
    case GET_ALL_SUB_ACCOUNTS_SUCCESS:
      return { ...state, accountsList: action.payload }

    // submit new account form details
    case SUBMIT_SUB_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        inProgress: true,
        isSuccess: false,
        errorMsg: ''
      }
    case SUBMIT_SUB_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        inProgress: false,
        isSuccess: true,
        errorMsg: ''
      }
    case SUBMIT_SUB_ACCOUNT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        inProgress: false,
        isSuccess: false,
        errorMsg: action.payload
      }
    default:
      return state
  }
}
