import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { HashRouter } from "react-router-dom";

// REDUX
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import persistReducer from './Redux/Reducers/index';
import Spinner from "./views/Spinner/Spinner";

let persistor = persistStore(persistReducer);

//import reportWebVitals from './reportWebVitals';
ReactDOM.render(
  <Provider store={persistReducer}>
    <PersistGate persistor={persistor}>
    <Suspense fallback={<Spinner />}>
      <HashRouter>
        <App />
      </HashRouter>
    </Suspense>
    </PersistGate>
    </Provider>,
  document.getElementById("root") 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
