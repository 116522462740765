import {
  ACCT_DETAILS_SAVE,
  ACCT_DETAILS_REQUEST,
  CLEAR_FORM,
  GET_COA_LIST_SAVE,
  SUBMIT_ACCOUNT_DETAILS_SUCCESS,
  SUBMIT_ACCOUNT_DETAILS_REQUEST,
  SUBMIT_PAYMENT_ACCOUNT_SUCCESS,
  SUBMIT_PAYMENT_ACCOUNT_FAIL,
  SUBMIT_PAYMENT_ACCOUNT_REQUEST,
  GET_ALL_PAYMENT_ACCT_REQUEST,
  GET_ALL_PAYMENT_ACCT_SUCCESS,
  GET_ALL_PAYMENT_ACCT_FAIL,
  EDIT_ENTRY_SUCCESS,
  EXPORT_ACCOUNT_SUCCESS
} from '../Actions/Constant'

let initialState = {
  accountDetails: {
    paymentType: '',
    accountName: '',
    status: 'active',
    currency: 'PKR',
    accountTitle: '',
    accountNumber: '',
    bank: '',
    bankType: 'CurrentAccount',
    branchAddress: '',
    iBAN: '',
    accountId: '',
    account: '',
    swiftCode: '',
    create: true,
    view: false,
    edit: false
  },
  CSV_Success: false,
  CSV_Url: '',
  accountList: [],
  paymentAccountList: [],
  loading: false,
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: ''
}

export function PaymentAccountDetails(state = initialState, action) {
  switch (action.type) {
    // clear All
    case CLEAR_FORM:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          paymentType: 'Cash',
          accountName: '',
          status: 'active',
          currency: 'PKR',
          accountTitle: '',
          accountNumber: '',
          bank: '',
          bankType: 'CurrentAccount',
          branchAddress: '',
          iBAN: '',
          accountId: '',
          account: '',
          swiftCode: '',
          create: true,
          view: false,
          edit: false
        },
        loading: false,
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }

    //  Save New Account Details
    case ACCT_DETAILS_REQUEST:
      return { ...state }
    case ACCT_DETAILS_SAVE:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          [action.payload.name]: action.payload.value
        }
      }
    //saving COA List
    case GET_COA_LIST_SAVE:
      return { ...state, accountList: action.payload }
    // submit new account form details
    case SUBMIT_PAYMENT_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        inProgress: true,
        isSuccess: false,
        errorMsg: ''
      }
    case SUBMIT_PAYMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        inProgress: false,
        isSuccess: true,
        errorMsg: ''
      }
    case SUBMIT_PAYMENT_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        inProgress: false,
        isSuccess: false,
        errorMsg: action.payload
      }
    // getting all Payment Account
    case GET_ALL_PAYMENT_ACCT_REQUEST:
      return {
        ...state,
        loading: true,
        inProgress: true,
        error: false,
        paymentAccountList: []
      }
    case GET_ALL_PAYMENT_ACCT_SUCCESS:
      return {
        ...state,
        loading: false,
        inProgress: false,
        // isSuccess: true,
        // error: false,
        paymentAccountList: action.payload
      }
    case GET_ALL_PAYMENT_ACCT_FAIL:
      return {
        ...state,
        loading: false,
        // error: true,
        inProgress: false,
        // isSuccess: false,
        paymentAccountList: [],
        errorMsg: action.payload
      }
    //  Edit Entry
    case EDIT_ENTRY_SUCCESS:
      const { type } = action.payload
      const {
        accountId,
        account,
        accountName,
        accountNumber,
        accountTitle,
        bank,
        bankType,
        branchAddress,
        createdAt,
        currency,
        iBAN,
        paymentType,
        status,
        swiftCode,
        _id
      } = action.payload.row
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          paymentType: paymentType,
          _id: _id,
          accountName: accountName,
          status: status,
          currency: currency,
          accountTitle: accountTitle,
          accountNumber: accountNumber,
          bank: bank,
          bankType: bankType,
          branchAddress: branchAddress,
          iBAN: iBAN,
          swiftCode: swiftCode,
          accountId: accountId._id,
          account: account,
          create: type === 'view' || type === 'edit' ? false : true,
          view: type === 'view' ? true : false,
          edit: type === 'edit' ? true : false,
        }
      }
    // export to CSV
    case EXPORT_ACCOUNT_SUCCESS:
      return { ...state, CSV_Success: true, CSV_Url: action.payload.path }
    default:
      return state
  }
}
