import { CallToActionSharp } from '@material-ui/icons'
import moment from 'moment'
import {
  FILTER_GL_ENTRY_REQUEST,
  FILTER_GL_ENTRY_SUCCESS,
  CLEAR_FILTER_GL_ENTRY,
  SUBMIT_FILTER_GL_ENTRY_FAIL,
  SUBMIT_FILTER_GL_ENTRY_REQUEST,
  SUBMIT_FILTER_GL_ENTRY_SUCCESS,
  CLEAR_GL_ENTRY
} from '../Actions/Constant'

let initialState = {
  filter: {
    from: moment(new Date()).format('YYYY-MM-DD'),
    to: moment(new Date()).format('YYYY-MM-DD'),
    sortBy: 'accountName',
    coa: null,
    coaID: '',
    cashBasis: false,
    showYTD: false,
    create: true,
    view: false,
    edit: false
  },
  CSV_Success: false,
  CSV_Url: '',
  loading: false,
  glSummary: [],
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: ''
}

export function GeneralLedgerReport(state = initialState, action) {
  switch (action.type) {
    // clear fILTERS
    case CLEAR_FILTER_GL_ENTRY:
      return {
        ...state,
        filter: {
          from: moment(new Date()).format('YYYY-MM-DD'),
          to: moment(new Date()).format('YYYY-MM-DD'),
          sortBy: 'accountName',
          coa: null,
          coaID: '',
          cashBasis: false,
          showYTD: false,
          create: true,
          view: false,
          edit: false
        },
        loading: false,
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: '',
        glSummary: []
      }

    // clear 
    case CLEAR_GL_ENTRY:
      return {
        ...state,
        loading: false,
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }

    //  Save filter Dates of General Ledger Report
    case FILTER_GL_ENTRY_REQUEST:
      return { ...state }
    case FILTER_GL_ENTRY_SUCCESS:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.name]: action.payload.value
        }
      }

    // submit filter and get GL Report
    case SUBMIT_FILTER_GL_ENTRY_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        inProgress: true,
        isSuccess: false,
        errorMsg: ''
      }
    case SUBMIT_FILTER_GL_ENTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        inProgress: false,
        isSuccess: true,
        glSummary: action.payload,
        errorMsg: ''
      }
    case SUBMIT_FILTER_GL_ENTRY_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        inProgress: false,
        isSuccess: false,
        glSummary: [],
        errorMsg: action.payload
      }

    // //  Get Account Types
    // case GET_ALL_ACCOUNTS_TYPES_SUCCESS:
    //   return { ...state, accounts: action.payload }

    // // Edit Account
    // case EDIT_ACCOUNT_SUCCESS:
    //   const { type } = action.payload
    //   const {
    //     AccParentId,
    //     name,
    //     AccParentName,
    //     description,
    //     code,
    //     create,
    //     view,
    //     _id,
    //     edit } = action.payload.row
    //   return {
    //     ...state,
    //     accountDetails: {
    //       ...state.accountDetails,
    //       AccParentId: AccParentId || '',
    //       name: name || '',
    //       AccParentName: AccParentName,
    //       description: description,
    //       code: code,
    //       id: _id,
    //       create: type === 'view' || type === 'edit' ? false : true,
    //       view: type === 'view' ? true : false,
    //       edit: type === 'edit' ? true : false
    //     }
    //   }

    // // export to CSV 
    // case GET_ALL_SUB_ACCOUNTS_SUCCESS:
    //   return { ...state, accountsList: action.payload }


    // case SUBMIT_SUB_ACCOUNT_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: false,
    //     inProgress: false,
    //     isSuccess: true,
    //     errorMsg: ''
    //   }
    // case SUBMIT_SUB_ACCOUNT_DETAILS_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: true,
    //     inProgress: false,
    //     isSuccess: false,
    //     errorMsg: action.payload
    //   }
    default:
      return state
  }
}
