import React, { useState } from 'react'
import { useNavigate, useRoutes, useLocation } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import { baseTheme } from './assets/global/Theme-variable'
import Themeroutes, { AuthRoutes } from './routes/Router'
// import { Provider } from 'react-redux';
// import store from 'store';
import { useDispatch, useSelector } from 'react-redux'
import { getGeneralConfig } from './Redux/Actions/GeneralSetup'
import { addLineRow } from './Redux/Actions/GLForm'
import { clearForm } from './Redux/Actions/DepositVoucher'
import { clearForm as clearPaymentForm } from './Redux/Actions/PaymentVoucher'
import { clearForm as clearGLForm } from './Redux/Actions/GLForm'
import { setToken, setPermissions } from './Redux/Actions/user'
import { saveAccountsCode } from './Redux/Actions/GeneralSetup'
import { errorAlert } from './Functions'
import axios from 'axios'

const App = () => {
  const routing = useRoutes(Themeroutes)
  const authRouting = useRoutes(AuthRoutes)
  const theme = baseTheme
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { Token } = useSelector((state) => state.user)
  const userToken = Token || localStorage.getItem('authjwt')
  const Urltoken = new URLSearchParams(window.location.search).get('token') // token={ selectedRoute: route, Token: Token } like route='/' & Token= user validate Token

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'F8') {
        navigate('/AddGLEntry')
        dispatch(clearGLForm())
      }
      if (event.key === 'F9') {
        navigate('/AddPaymentVoucher')
        dispatch(clearPaymentForm())
      }
      if (event.key === 'F10') {
        navigate('/AddDepositVoucher')
        dispatch(clearForm())
      }
      if (event.key === 'm' && event.altKey) {
        // e.g. altKey + M
        navigate('/dashboard')
      }
      if (event.key === 'g' && event.altKey) {
        // e.g. altKey + G
        navigate('/GLTable')
      }
      if (event.key === 'c' && event.altKey) {
        // e.g. altKey + C
        navigate('/ChartofAccounts')
      }
      if (event.key === 'i' && event.altKey) {
        // e.g. altKey + I
        navigate('/GLIquiry')
      }
      if (event.key === 'a' && event.altKey) {
        // e.g. altKey + A
        navigate('/PaymentAccountDetails')
      }
      if (event.key === 'p' && event.altKey) {
        // e.g. altKey + P
        navigate('/PaymentVoucher')
      }
      if (event.key === 'd' && event.altKey) {
        // e.g. altKey + D
        navigate('/DespositVoucher')
      }
      if (event.key === 'r' && event.altKey) {
        // e.g. altKey + R
        navigate('/Report')
      }
      if (event.key === 'n' && event.altKey) {
        // e.g. altKey + N
        dispatch(addLineRow())
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [navigate])

  // ** we use to link invoice mate pro with AccountMate on the basis to Tokken
  React.useLayoutEffect(() => {
    const Urltoken = new URLSearchParams(window.location.search).get('token') // token={ selectedRoute: route, Token: Token } like route='/' & Token= user validate Token
    if (Urltoken !== null) {
      const { selectedRoute, Token } = JSON.parse(Urltoken)
      if (Token && !userToken) {
        dispatch(getGeneralConfig(Token, navigate, dispatch))
        getAccountCodes(Token)
        console.log(selectedRoute)
        getPermissions(Token, selectedRoute)
      } else if (Token && userToken) {
        dispatch(getGeneralConfig(Token, navigate, dispatch))
        getAccountCodes(Token)
        getPermissions(Token, selectedRoute)
      }
    }
  }, [dispatch])

  const getAccountCodes = async (token) => {
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/generalConfig/getCodeConfiguration`,
      headers: { authorization: token }
    })
      .then((response) => {
        dispatch(saveAccountsCode(response.data))
      })
      .catch((error) => {
        errorAlert(error?.response?.data?.msg)
      })
  }

  const redirect = () => {
    navigate('/login')
  }

  const getPermissions = async (token, selectedRoute) => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/user/activateOtp`,
      headers: { cooljwt: token },
      data: {
        otp: '',
        otpAccess: false
      }
    })
      .then((response) => {
        localStorage.setItem('authjwt', token)
        localStorage.setItem('permissions', JSON.stringify(response.data))
        dispatch(setPermissions(response.data))
        dispatch(setToken(token))
        if (selectedRoute) {
          window.location.href = `${process.env.REACT_APP_ACCOUNTSMATE_URL}/#${selectedRoute}`
        } else {
          navigate('/login')
        }
      })
      .catch((error) => {
        errorAlert(
          error?.response?.data
            ? error?.response?.data
            : error?.response?.data?.msg
        )
      })
  }

  return userToken ? (
    <ThemeProvider theme={theme}>{routing}</ThemeProvider>
  ) : (
    !userToken && (
      <ThemeProvider theme={theme}>
        {!Token && Urltoken ? '' : authRouting}
      </ThemeProvider>
    )
  )
}

export default App
