import { CallToActionSharp } from '@material-ui/icons'
import moment from 'moment'
import {
  GET_DATE_BY_TYPE_SUCCESS,
  TRAIL_BAL_FILTERS_REQUEST,
  TRAIL_BAL_FILTERS_SUCCESS,
  GET_TRAIL_BAL_REPORT_REQUEST,
  GET_TRAIL_BAL_REPORT_SUCCESS,
  GET_TRAIL_BAL_REPORT_FAIL,
  CLEAR_ALL_DATA,
  SET_REPORT_EMPTY,
  CLEAR_FILTERS,
  GET_PREVIOUS_DATES_SUCCESS,
} from '../Actions/Constant'

let initialState = {
  filter: {
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    date: moment(new Date()).format('YYYY-MM-DD'),
    dateWithFilter: '',
    compareWith: '1',
    period: '',
    grouping: 'groupBy',
    groupingType: 'accountType',
  },
  report: [],
  previousDates: [],
  credit: '',
  debit: '',
  loading: false,
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: ''
}

export function TrailBalance(state = initialState, action) {
  switch (action.type) {
    // set filter
    case TRAIL_BAL_FILTERS_REQUEST:
      return { ...state }
    case TRAIL_BAL_FILTERS_SUCCESS:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.name]: action.payload.value
        }
      }
    case SET_REPORT_EMPTY:
      return {
        ...state,
        report: []
      }
    case CLEAR_FILTERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          startDate: moment(new Date()).format('YYYY-MM-DD'),
          date: moment(new Date()).format('YYYY-MM-DD'),
          dateWithFilter: '',
          compareWith: '1',
          period: '',
          grouping: 'groupBy',
          groupingType: 'accountType',
        },
        report: [],
        previousDates: [],
        credit: '',
        debit: '',
      }
    case GET_DATE_BY_TYPE_SUCCESS:
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.payload
        }
      }
    case GET_PREVIOUS_DATES_SUCCESS:
      return {
        ...state,
        previousDates: action.payload
      }
    case GET_TRAIL_BAL_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        inProgress: true,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }
    case GET_TRAIL_BAL_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        inProgress: false,
        isSuccess: true,
        report: action.payload.data,
        credit: action.payload.credit,
        debit: action.payload.debit,
        creditClosing: action.payload.creditClosing,
        debitClosing: action.payload.debitClosing,
        debitPeriod: action.payload.debitPeriod,
        creditPeriod: action.payload.creditPeriod,
        error: false,
        errorMsg: ''
      }
    case GET_TRAIL_BAL_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        inProgress: false,
        isSuccess: false,
        report: [],
        credit: '',
        debit: '',
        error: true,
        errorMsg: action.payload
      }
    case CLEAR_ALL_DATA:
      return {
        filter: {
          startDate: moment(new Date()).format('YYYY-MM-DD'),
          date: moment(new Date()).format('YYYY-MM-DD'),
          dateWithFilter: '',
          compareWith: '1',
          period: '',
          grouping: 'groupBy',
          groupingType: 'accountType',
        },
        report: [],
        previousDates: [],
        credit: '',
        debit: '',
        loading: false,
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }
    default:
      return state
  }
}
