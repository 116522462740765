import axios from 'axios'
import { SessionExpried } from '../../Functions';
import {
  GS_ACCOUNT_SETUP_REQUEST,
  GS_ACCOUNT_SETUP_SUCCESS,
  CLEAR_GS_ACCOUNT_SETUP,
  GET_GENERAL_CONFIG_REQUEST,
  GET_GENERAL_CONFIG_SUCCESS,
  GET_GENERAL_CONFIG_FAIL,
  UPDATE_GENERAL_CONFIG_REQUEST,
  UPDATE_GENERAL_CONFIG_SUCCESS,
  UPDATE_GENERAL_CONFIG_FAIL,
  CLEAR_GS_STATE,
  SAVE_ACCOUNT_CODES_REQUEST,
  SAVE_ACCOUNT_CODES_SUCCESS
} from './Constant'

const token = localStorage.getItem('authjwt');

// entering data to form
export const CreateAccount = (name, value) => async dispatch => {
  try {
    dispatch({ type: GS_ACCOUNT_SETUP_REQUEST })

    dispatch({ type: GS_ACCOUNT_SETUP_SUCCESS, payload: { name, value } })
  } catch (error) {
    console.log(error)
  }
}

// entering data to form
export const saveAccountsCode = (data) => async dispatch => {
  try {
    dispatch({ type: SAVE_ACCOUNT_CODES_REQUEST })

    dispatch({ type: SAVE_ACCOUNT_CODES_SUCCESS, payload: data })
  } catch (error) {
    console.log(error)
  }
}


export const clearForm = () => async dispatch => {
  try {
    dispatch({ type: CLEAR_GS_ACCOUNT_SETUP, payload: '' })
  } catch (error) {
    console.log(error)
  }
}
export const clearState = () => async dispatch => {
  try {
    dispatch({ type: CLEAR_GS_STATE, payload: '' })
  } catch (error) {
    console.log(error)
  }
}

// GET GENERAL CONFIGURATIONS
export const getGeneralConfig = (token, navigate, Dispatch) => async dispatch => {
  try {
    dispatch({ type: GET_GENERAL_CONFIG_REQUEST })
    const { data } = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/generalConfig/getGeneralConfig`,
      headers: { authorization: token },
    })
    dispatch({ type: GET_GENERAL_CONFIG_SUCCESS, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({
        type: GET_GENERAL_CONFIG_FAIL,
        payload: error.response?.data || ''
      })
    }
  }
}
// UPDATE GENERAL CONFIGURATIONS
export const updateGeneralConfig = (enterpriseDetails, token, navigate, Dispatch) => async dispatch => {
  const {
    enterpriseName,
    coaName,
    phoneNumber,
    email,
    companyId,
    GSTNumber,
    primaryCurrency,
    FYstartDate,
    decimalRounding,
    coaCodeLength,
    accountingBases,
    finyear,
    address,
    interCompanyTXN,
    logo,
    timeZone,
    controlAcctLenght,
    controlAcctCodeLevel
  } = enterpriseDetails;
  try {
    dispatch({ type: UPDATE_GENERAL_CONFIG_REQUEST })
    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/generalConfig/addGeneralConfig`,
      headers: { authorization: token },
      data: {
        enterpriseName: enterpriseName,
        coaName: coaName,
        phoneNumber: phoneNumber,
        email: email,
        companyId: companyId,
        GSTNumber: GSTNumber,
        primaryCurrency: primaryCurrency,
        FYstartDate: FYstartDate,
        decimalRounding: decimalRounding,
        coaCodeLength: coaCodeLength,
        controlAcctLenght: controlAcctLenght,
        controlAcctCodeLevel:controlAcctCodeLevel,
        accountingBases: accountingBases,
        finyear: finyear,
        address: address,
        interCompanyTXN: interCompanyTXN ? interCompanyTXN : false,
        logo: logo,
        timeZone: timeZone,
      }
    })
    dispatch({ type: UPDATE_GENERAL_CONFIG_SUCCESS, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({
        type: UPDATE_GENERAL_CONFIG_FAIL,
        payload: error.response?.data || ''
      })
    }

  }
}