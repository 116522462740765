import {
  ADD_LINE_SUCCESS,
  CLEAR_ALL_VOUCHER_FORM,
  CLEAR_DOWNLOAD_ENTRY,
  CLEAR_LINE_ITEMS,
  DOWNLOAD_ENTRY_FAIL,
  DOWNLOAD_ENTRY_REQUEST,
  DOWNLOAD_ENTRY_SUCCESS,
  EDIT_VOUCHER_DETAILS_FAIL,
  EDIT_VOUCHER_DETAILS_SUCCESS,
  EXPORT_VOUCHER_FAIL,
  EXPORT_VOUCHER_REQUEST,
  EXPORT_VOUCHER_SUCCESS,
  DEPOSIT_ATTACHMENTS_REQUEST,
  DEPOSIT_ATTACHMENTS_SUCCESS,
  DEPOSIT_ATTACHMENTS_DELETE,
  GET_ALL_VOUCHERS_FAIL,
  GET_ALL_VOUCHERS_REQUEST,
  GET_ALL_VOUCHERS_SUCCESS,
  GET_PAYMENT_ACCT_FAIL,
  GET_PAYMENT_ACCT_REQUEST,
  GET_PAYMENT_ACCT_SUCCESS,
  LINE_ITEMS_DELETE,
  LINE_ITEMS_EDIT,
  LINE_ITEMS_SUBMIT,
  SUBMIT_VOUCHER_FAIL,
  SUBMIT_VOUCHER_REQUEST,
  SUBMIT_VOUCHER_SUCCESS,
  VOUCHER_DETAILS_REQUEST,
  VOUCHER_DETAILS_SAVE,
  VOUCHER_LINES_DELETE,
  VOUCHER_LINES_DETAILS_REQUEST,
  VOUCHER_LINES_DETAILS_SAVE,
  VOUCHER_VALIDATE_DATE_FAIL,
  VOUCHER_VALIDATE_DATE_REQUEST,
  VOUCHER_VALIDATE_DATE_SUCCESS,
  _COA_LIST_FAIL,
  _COA_LIST_REQUEST,
  _COA_LIST_SAVE,
  UPDATE_LINE_ITEMS
} from './Constant'
import axios from 'axios'
import { SessionExpried } from '../../Functions';

const token = localStorage.getItem('authjwt');

// entering data to form
export const CreateAccount = (name, value) => async dispatch => {
  try {
    dispatch({ type: VOUCHER_DETAILS_REQUEST })

    dispatch({ type: VOUCHER_DETAILS_SAVE, payload: { name, value } })
  } catch (error) {
    console.log(error)
  }
}
// VALIDATE DATE 
export const validateDate = (value, token, navigate, Dispatch) => async dispatch => {
  try {
    dispatch({ type: VOUCHER_VALIDATE_DATE_REQUEST })

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/financialConfiguration/checkDate`,

      headers: { authorization: token },
      data: { journalDate: value }
    })
    dispatch({ type: VOUCHER_VALIDATE_DATE_SUCCESS, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({ type: VOUCHER_VALIDATE_DATE_FAIL, payload: error.message })
    }
  }
}

// entering data to Lines
export const createLineRow = (name, value, index, row) => async dispatch => {
  try {
    dispatch({ type: VOUCHER_LINES_DETAILS_REQUEST })

    dispatch({ type: VOUCHER_LINES_DETAILS_SAVE, payload: { name, value, index, row } })
  } catch (error) {
    console.log(error)
  }
}
// Add new Line Row
export const addLineRow = () => async dispatch => {
  try {
    dispatch({ type: ADD_LINE_SUCCESS, payload: '' })
  } catch (error) {
    console.log(error)
  }
}
// Delete Line Row
export const lineDelete = (isCredit, Index) => async dispatch => {
  dispatch({ type: VOUCHER_LINES_DELETE, payload: isCredit, Index })
}

export const updateLineItems = (lines) => async dispatch => {
  dispatch({ type: UPDATE_LINE_ITEMS, payload: { lines } })
}


// clear All Form
export const clearForm = () => async dispatch => {
  try {
    dispatch({ type: CLEAR_ALL_VOUCHER_FORM, payload: '' })
  } catch (error) {
    console.log(error)
  }
}
// clear line Items
export const clearCreditForm = () => async dispatch => {
  try {
    dispatch({ type: CLEAR_LINE_ITEMS, payload: '' })
  } catch (error) {
    console.log(error)
  }
}

export const CreditItemsSubmit = editIndex => async dispatch => {
  dispatch({ type: LINE_ITEMS_SUBMIT, payload: { editIndex } })
}

export const CreditItemsDelete = Index => async dispatch => {
  dispatch({ type: LINE_ITEMS_DELETE, payload: Index })
}

export const CreditItemsEdit = (row, index, Edit) => async dispatch => {
  dispatch({ type: LINE_ITEMS_EDIT, payload: { row, index, Edit } })
}
// getting COA Accounts List
export const getAccountList = (token, navigate, Dispatch) => async dispatch => {
  try {
    dispatch({ type: _COA_LIST_REQUEST })

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/lov/getListofChartsOfAccountwithoutGroup?search=`,
      {
        headers: { authorization: token },
      }
    )
    dispatch({ type: _COA_LIST_SAVE, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({ type: _COA_LIST_FAIL, payload: error.message })
    }
  }
}
// submit new GL Entries
export const submitDepositVoucher = (VoucherDetails, lineRows, edit, saved_attachments, token, navigate, Dispatch) => async dispatch => {
  let formData = {
    _id: VoucherDetails.values.id,
    taxType: VoucherDetails.values.taxType,
    depositDate: new Date(VoucherDetails.values.depositDate),
    depositType: VoucherDetails.values.depositType,
    toAccount: VoucherDetails.values.toAccount,
    depositMode: VoucherDetails.values.depositMode,
    receivedForm: VoucherDetails.values.receivedForm,
    voucherRef: VoucherDetails.values.voucherRef,
    instrumentNumber: VoucherDetails.values.instrumentNumber,
    totalAmount: Number(VoucherDetails.values.totalAmount),
    depositerName: VoucherDetails.values.depositerName,
    description: VoucherDetails.values.description,
    source: VoucherDetails.values.source,
    addDebitLine: lineRows,
    attachments: saved_attachments
  }
  try {
    dispatch({ type: SUBMIT_VOUCHER_REQUEST })
    const { data } = await axios({
      method: 'post',
      url: !edit
        ? `${process.env.REACT_APP_API_URL}/deposits/addDepositVoucher`
        : `${process.env.REACT_APP_API_URL}/deposits/updateDepositVoucher`,
      headers: { authorization: token },
      data: formData
    })
    dispatch({ type: SUBMIT_VOUCHER_SUCCESS, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({
        type: SUBMIT_VOUCHER_FAIL,
        payload: error.response?.data || ''
      });
    }
  }
}

// Download Entry
export const downloadEntry = (id, type, token, navigate, Dispatch) => async dispatch => {
  try {
    dispatch({ type: DOWNLOAD_ENTRY_REQUEST })

    const { data } = await axios({
      method: 'get',
      url: type === 'PDF' ? `${process.env.REACT_APP_API_URL}/deposits/depositPDF/${id}`
        : `${process.env.REACT_APP_API_URL}/deposits/getVoucherListcsv/${id}`,
      headers: { authorization: token },
    })
    dispatch({ type: DOWNLOAD_ENTRY_SUCCESS, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({ type: DOWNLOAD_ENTRY_FAIL, payload: error?.response?.data ? error.response?.data : error?.response?.msg });
    }
  }
}

// Clear Download Entry
export const handleClearDownload = () => async dispatch => {

  dispatch({ type: CLEAR_DOWNLOAD_ENTRY, payload: '' })

}

// Get All GL Entires
export const getAllVouchers = (page, rowPerPage, search, token, navigate, Dispatch) => async dispatch => {
  try {
    dispatch({ type: GET_ALL_VOUCHERS_SUCCESS })
    const { data } = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/deposits/getVoucherList?pageNumber=${page}&pageSize=${rowPerPage}&search=${search}&paging=${true}`,
      headers: { authorization: token },
    })
    dispatch({ type: GET_ALL_VOUCHERS_SUCCESS, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({
        type: GET_ALL_VOUCHERS_FAIL,
        payload: error.response?.data || ''
      });
    }
  }
}

// Edit GL Entry
export const editVoucher = (row, type) => async dispatch => {
  try {
    dispatch({ type: EDIT_VOUCHER_DETAILS_SUCCESS, payload: { row, type } })
  } catch (error) {
    console.log(error);
    dispatch({
      type: EDIT_VOUCHER_DETAILS_FAIL,
      payload: ''

    })
  }
}
// Get PaymentAccount of the base of type
export const getPaymentAccounts = (type, token, navigate, Dispatch) => async dispatch => {
  try {
    dispatch({ type: GET_PAYMENT_ACCT_REQUEST })
    const { data } = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/paymentAcc/getPaymentACCListByPaymentType?paymentType=${type}`,
      headers: { authorization: token },
    })
    dispatch({ type: GET_PAYMENT_ACCT_SUCCESS, payload: data })
  } catch (error) {
    if (error?.response?.status === 401) {
      SessionExpried(navigate, Dispatch);
    } else {
      dispatch({
        type: GET_PAYMENT_ACCT_FAIL,
        payload: error.response?.data || ''
      })
    }
  }
}
// Export Voucher As CSV
export const exportVouchers = (page, rowPerPage, search) => async dispatch => {
  try {
    dispatch({ type: EXPORT_VOUCHER_REQUEST })
    const { data } = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/glEntry/GLExrtToCsv?pageNumber=${page}&pageSize=${rowPerPage}&search=${search}`
    })

    dispatch({ type: EXPORT_VOUCHER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: EXPORT_VOUCHER_FAIL,
      payload: error.response?.data || ''
    })
  }
}

// Attachment SAVE
export const saveAttachments = fileData => async dispatch => {
  try {
    dispatch({ type: DEPOSIT_ATTACHMENTS_REQUEST })

    dispatch({ type: DEPOSIT_ATTACHMENTS_SUCCESS, payload: fileData })
  } catch (error) {

  }
}
// Attachment DELETE
export const AttachmentsDelete = fileIndex => async dispatch => {
  dispatch({ type: DEPOSIT_ATTACHMENTS_DELETE, payload: fileIndex })
}