import moment from 'moment'
import {
  ADD_NEW_GL_LINE_SUCCESS,
  CLEAR_ALL_GL_FORM,
  CLEAR_CREDIT_FORM,
  CLEAR_DEBIT_FORM,
  COPY_NARRATION,
  CREDIT_ITEMS_DELETE,
  CREDIT_ITEMS_EDIT,
  CREDIT_ITEMS_SUBMIT,
  DEBIT_ITEMS_DELETE,
  DEBIT_ITEMS_EDIT,
  DEBIT_ITEMS_SUBMIT,
  EDIT_GL_ENTRY_SUCCESS,
  EXPORT_GL_ENTRY_SUCCESS,
  GET_ALL_GL_ENTRY_FAIL,
  GET_ALL_GL_ENTRY_REQUEST,
  GET_ALL_GL_ENTRY_SUCCESS,
  GET_COA_LIST_SAVE,
  GET_CURRENCIES_SAVE,
  GET_GL_TAX_LIST_SUCCESS,
  GL_ATTACHMENTS_DELETE,
  GL_ATTACHMENTS_REQUEST,
  GL_ATTACHMENTS_SUCCESS,
  GL_DETAILS_REQUEST,
  GL_DETAILS_SAVE,
  GL_LINES_DETAILS_REQUEST,
  GL_LINES_DETAILS_SAVE,
  GL_VALIDATE_DATE_FAIL,
  GL_VALIDATE_DATE_REQUEST,
  GL_VALIDATE_DATE_SUCCESS,
  SUBMIT_GL_ENTRY_FAIL,
  SUBMIT_GL_ENTRY_REQUEST,
  SUBMIT_GL_ENTRY_SUCCESS,
  UPDATE_ITEMS_SUBMIT
} from '../Actions/Constant'

let initialState = {
  GLEntries: {
    values: {
      id: null,
      statusType: 'Draft',
      journalDate: moment(new Date()).format('YYYY-MM-DD'),
      narration: '',
      taxType: 'NoTax',
      source:'Manual',
      TaxAmt: 0,
      coaAcctName: '',
      TaxDebitAdjustment: '',
      TaxCreditAdjustment: '',
      taxValue: 0,
      taxRateId: '',
      coaAccount: '',
      isCredit: false,
      lineDescription: '',
      TaxRate: '',
      debitAccount: '',
      debitaccountID: '',
      debitDescription: '',
      debitAmount: '',
      debitSubTotal: '',
      debitTotal: '',
      debitTaxAmt: 0,
      creditAccount: '',
      creditaccountID: '',
      creditDescription: '',
      creditAmount: '',
      creditSubTotal: '',
      creditTotal: '',
      creditTaxAmt: 0,
      TaxAmtDebit: 0,
      TaxAmtCredit: 0,
      TotalOutBy: 0,
      voucherRef: '',
      create: true,
      view: false,
      edit: false
    },
    taxList: [],
    debit_Lines: [],
    LinesItems: [],
    credit_Lines: [],
    saved_attachments: []
  },
  CSV_Success: false,
  CSV_Url: '',
  lineRows: [{
    coaId: '',
    coa: '',
    errorCoa: '',
    isCredit: false,
    lineDescription: '',
    debitAmount: 0,
    errorDebit: '',
    creditAmount: 0,
    errorCredit: '',
    TaxRate: 0,
    taxRateId: '',
    errorTaxRate: '',
    errorTaxAmt: '',
    TaxAmt: 0,
    taxValue: 0,
  },
  {
    coaId: '',
    coa: '',
    errorCoa: '',
    isCredit: false,
    lineDescription: '',
    debitAmount: 0,
    errorDebit: '',
    creditAmount: 0,
    errorCredit: '',
    TaxRate: 0,
    taxRateId: '',
    TaxAmt: 0,
    taxValue: 0,
    errorTaxRate: '',
    errorTaxAmt: '',
  }],
  currencies: [],
  accountList: [],
  GLEntriesList: [],
  addAnotherEntry: false,
  loading: false,
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: '',
  validateLoading: false,
  validateSuccess: false,
  validateError: false,
  validationMsg: ''

}

export function GLFormEntries(state = initialState, action) {
  switch (action.type) {
    //  Save New Account Details
    case GL_DETAILS_REQUEST:
      return { ...state }
    case GL_DETAILS_SAVE:
      return {
        ...state,
        GLEntries: {
          ...state.GLEntries,
          values: {
            ...state.GLEntries.values,
            [action.payload.name]: action.payload.value
          }
        }
      }
    //  Save New Line Row Details
    case GL_LINES_DETAILS_REQUEST:
      return { ...state }
    case GL_LINES_DETAILS_SAVE:
      let rows_new = [...state.lineRows]
      let object = { ...rows_new[action.payload.index], [action.payload.name]: action.payload.value }
      rows_new[action.payload.index] = object
      return {
        ...state,
        lineRows: rows_new
      }

    // clear All Form
    case CLEAR_ALL_GL_FORM:
      return {
        ...state,
        GLEntries: {
          values: {
            id: null,
            statusType: 'Draft',
            journalDate: moment(new Date()).format('YYYY-MM-DD'),
            narration: '',
            taxType: 'NoTax',
            source:'Manual',
            TaxAmt: 0,
            coaAcctName: '',
            TaxDebitAdjustment: '',
            TaxCreditAdjustment: '',
            taxValue: 0,
            taxRateId: '',
            coaAccount: '',
            isCredit: false,
            lineDescription: '',
            TaxRate: '',
            debitAccount: '',
            debitaccountID: '',
            debitDescription: '',
            debitAmount: '',
            debitSubTotal: '',
            debitTotal: '',
            debitTaxAmt: 0,
            creditAccount: '',
            creditaccountID: '',
            creditDescription: '',
            creditAmount: '',
            creditSubTotal: '',
            creditTotal: '',
            creditTaxAmt: 0,
            TaxAmtDebit: 0,
            TaxAmtCredit: 0,
            TotalOutBy: 0,
            voucherRef: '',
            create: true,
            view: false,
            edit: false
          },
          taxList: [],
          debit_Lines: [],
          LinesItems: [],
          credit_Lines: [],
          saved_attachments: []
        },
        CSV_Success: false,
        CSV_Url: '',
        lineRows: [{
          coaId: '',
          coa: '',
          errorCoa: '',
          isCredit: false,
          lineDescription: '',
          debitAmount: 0,
          errorDebit: '',
          creditAmount: 0,
          errorCredit: '',
          TaxRate: 0,
          taxRateId: '',
          TaxAmt: 0,
          taxValue: 0,
          errorTaxRate: '',
          errorTaxAmt: '',
        },
        {
          coaId: '',
          coa: '',
          errorCoa: '',
          isCredit: false,
          lineDescription: '',
          debitAmount: 0,
          errorDebit: '',
          creditAmount: 0,
          errorCredit: '',
          TaxRate: 0,
          taxRateId: '',
          TaxAmt: 0,
          taxValue: 0,
          errorTaxRate: '',
          errorTaxAmt: '',
        }],
        currencies: [],
        accountList: [],
        GLEntriesList: [],
        addAnotherEntry: false,
        loading: false,
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: '',
        validateLoading: false,
        validateSuccess: false,
        validateError: false,
        validationMsg: ''
      }
    // clear All Debit Form
    case CLEAR_DEBIT_FORM:
      return {
        ...state,
        GLEntries: {
          ...state.GLEntries,
          values: {
            ...state.GLEntries.values,
            coaAccount: '',
            coa: '',
            isCredit: false,
            lineDescription: '',
            debitAmount: 0,
            errorDebit: '',
            creditAmount: 0,
            errorCredit: '',
            TaxRate: 0,
            taxRateId: '',
            TaxAmt: 0,
            taxValue: 0,
            errorTaxRate: '',
            errorTaxAmt: '',

          }
        }
      }
    // Add new Line Row
    case ADD_NEW_GL_LINE_SUCCESS:
      let rows = state.lineRows
      let newRow = {
        coaId: '',
        coa: '',
        errorCoa: '',
        isCredit: false,
        lineDescription: '',
        debitAmount: 0,
        errorDebit: '',
        creditAmount: 0,
        errorCredit: '',
        TaxRate: 0,
        taxRateId: '',
        TaxAmt: 0,
        taxValue: 0,
        errorTaxRate: '',
        errorTaxAmt: '',
      }
      let lineArray = [...rows, newRow]
      return {
        ...state,
        lineRows: lineArray
      }
    // clear All CREDIT Form
    case CLEAR_CREDIT_FORM:
      return {
        ...state,
        GLEntries: {
          ...state.GLEntries,
          values: {
            ...state.GLEntries.values,
            creditAccount: '',
            creditaccountID: '',
            creditDescription: '',
            creditAmount: ''
          }
        }
      }
    // copy narration and paste on line description
    case COPY_NARRATION:
      let row_new = [...state.lineRows]
      let copiedNarration = state.GLEntries.values.narration
      let Object = { ...row_new[action.payload.index], ['lineDescription']: copiedNarration }
      row_new[action.payload.index] = Object
      return {
        ...state,
        lineRows: row_new
      };

    case UPDATE_ITEMS_SUBMIT:
      const { lines } = action.payload
      return {
        ...state,
        lineRows: lines,
      };

    // ADD NEW Lines
    case DEBIT_ITEMS_SUBMIT:
      let New_Debit_Details = {
        coaAccount: state.GLEntries.values.coaAccount,
        isCredit: state.GLEntries.values.isCredit,
        lineDescription: state.GLEntries.values.lineDescription,
        debitAmount: Number(state.GLEntries.values.debitAmount),
        creditAmount: Number(state.GLEntries.values.creditAmount),
        TaxRate: state.GLEntries.values.taxValue ? `${state.GLEntries.values.taxValue} % Tax` : '',
        TaxAmt: state.GLEntries.values.TaxAmt,
        taxRateId: state.GLEntries.values.taxRateId,
        isEditMode: false,
        taxAmount: state.GLEntries.values.TaxAmt,
        coaAcctName: state.GLEntries.values.coaAcctName
      }
      let newArray = state.GLEntries.LinesItems
      let debitArray = state.GLEntries.debit_Lines
      let creditArray = state.GLEntries.credit_Lines

      if (action.payload.editIndex !== null) {
        if (New_Debit_Details.isCredit) {
          creditArray[action.payload.editIndex] = New_Debit_Details
          newArray[action.payload.editIndex] = New_Debit_Details
        } else {
          debitArray[action.payload.editIndex] = New_Debit_Details
          newArray[action.payload.editIndex] = New_Debit_Details
        }
      } else {
        if (New_Debit_Details.isCredit) {
          creditArray = [...state.GLEntries.credit_Lines, New_Debit_Details]
          newArray = [...state.GLEntries.LinesItems, New_Debit_Details]

        }
        else {
          debitArray = [...state.GLEntries.debit_Lines, New_Debit_Details]
          newArray = [...state.GLEntries.LinesItems, New_Debit_Details]

        }
      }
      return {
        ...state,
        GLEntries: {
          ...state.GLEntries,
          debit_Lines: debitArray,
          credit_Lines: creditArray,
          LinesItems: newArray,

          values: {
            ...state.GLEntries.values,
            coaAccount: '',
            isCredit: false,
            lineDescription: '',
            debitAmount: '',
            creditAmount: '',
            TaxRate: '',
          }
        }
      }
    case DEBIT_ITEMS_DELETE:

      let _Lines = state.lineRows.filter(
        (a, index) => index !== action.Index
      )
      return {
        ...state,
        lineRows: _Lines
      }

    case DEBIT_ITEMS_EDIT:
      const { isEdit } = action.payload
      const {
        coaId,
        isCredit,
        lineDescription,
        debitAmount,
        TaxRate,
        creditAmount,
        isEditMode,
        TaxAmt,
        taxRateId
      } = action.payload.row
      return {
        ...state,
        GLEntries: {
          ...state.GLEntries,
          values: {
            ...state.GLEntries.values,
            coaAccount: coaId,
            isCredit: isCredit,
            lineDescription: lineDescription,
            debitAmount: debitAmount,
            creditAmount: action.payload.row.creditAmount,
            TaxRate: taxRateId,
            creditAmount: creditAmount,
            isEditMode, isEditMode,
            TaxAmt: TaxAmt,
          }
        }
      }

    // VALIDATE DATE
    case GL_VALIDATE_DATE_REQUEST:
      return {
        ...state,
        validateLoading: true,
        validateSuccess: false,
        validateError: false,
        validationMsg: ''
      }
    case GL_VALIDATE_DATE_SUCCESS:
      return {
        ...state,
        validateLoading: false,
        validateSuccess: true,
        validateError: false,
        validationMsg: action.payload
      }
    case GL_VALIDATE_DATE_FAIL:
      return {
        ...state,
        validateLoading: false,
        validateSuccess: false,
        validateError: true,
        validationMsg: ''

      }

    // Save Attachments
    case GL_ATTACHMENTS_REQUEST:
      return { ...state }
    case GL_ATTACHMENTS_SUCCESS:
      const fileData = action.payload
      let attachArray = state.GLEntries.saved_attachments;
      attachArray = [...state.GLEntries.saved_attachments, fileData]
      return {
        ...state,
        GLEntries: {
          ...state.GLEntries,
          saved_attachments: attachArray,
        }
      }
    case GL_ATTACHMENTS_DELETE:
      let attachments_detail = state.GLEntries.saved_attachments
      let attach = attachments_detail.filter(
        (a, index) => index !== action.payload
      )
      return {
        ...state,
        GLEntries: {
          ...state.GLEntries,
          saved_attachments: attach,
        }
      }
    // Credit Lines
    case CREDIT_ITEMS_SUBMIT:
      let New_Credit_Details = {
        creditaccountID: state.GLEntries.values.creditaccountID,
        creditAccount: state.GLEntries.values.creditAccount,
        creditAmount: Number(state.GLEntries.values.creditAmount),
        creditDescription: state.GLEntries.values.creditDescription
      }
      let CreditArray = state.GLEntries.credit_Lines

      if (action.payload.editIndex !== null) {
        CreditArray[action.payload.editIndex] = New_Credit_Details
      } else {
        CreditArray = [...state.GLEntries.credit_Lines, New_Credit_Details]
      }
      return {
        ...state,
        GLEntries: {
          ...state.GLEntries,
          credit_Lines: CreditArray,
          values: {
            ...state.GLEntries.values,
            creditAccount: '',
            creditDescription: '',
            creditAmount: ''
          }
        }
      }
    case CREDIT_ITEMS_DELETE:
      let credit_Array = state.GLEntries.credit_Lines
      let filter_Credit_Lines = credit_Array.filter(
        (a, index) => index !== action.payload
      )
      return {
        ...state,
        GLEntries: {
          ...state.GLEntries,
          credit_Lines: filter_Credit_Lines
        }
      }
    // case CREDIT_ITEMS_EDIT:
    //   const { Edit } = action.payload
    //   const {
    //     creditAccount,
    //     creditDescription,
    //     creditAmount,
    //     creditaccountID
    //   } = action.payload.row
    //   return {
    //     ...state,
    //     GLEntries: {
    //       ...state.GLEntries,
    //       values: {
    //         ...state.GLEntries.values,
    //         creditaccountID: Edit ? creditAccount.name : creditaccountID,
    //         creditAccount: creditAccount,
    //         creditDescription: creditDescription,
    //         creditAmount: creditAmount
    //       }
    //     }
    //   }
    //saving TAX List
    case GET_GL_TAX_LIST_SUCCESS:
      return { ...state, taxList: action.payload }
    //saving COA List
    case GET_COA_LIST_SAVE:
      return { ...state, accountList: action.payload }
    //saving Currency List
    case GET_CURRENCIES_SAVE:
      return { ...state, currencies: action.payload }
    case SUBMIT_GL_ENTRY_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        inProgress: true,
        isSuccess: false,
        errorMsg: ''
      }
    case SUBMIT_GL_ENTRY_SUCCESS:
      return {
        ...state,
        addAnotherEntry: action.payload.addAnother,
        loading: false,
        error: false,
        inProgress: false,
        isSuccess: true,
        errorMsg: ''
      }
    case SUBMIT_GL_ENTRY_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        inProgress: false,
        isSuccess: false,
        errorMsg: action.payload
      }
    // getting all GL Entries
    case GET_ALL_GL_ENTRY_REQUEST:
      return {
        ...state,
        inProgress: true
      }
    case GET_ALL_GL_ENTRY_SUCCESS:
      return {
        ...state,
        inProgress: false,
        GLEntriesList: action.payload
      }
    case GET_ALL_GL_ENTRY_FAIL:
      return {
        ...state,
        inProgress: false,
        GLEntriesList: []
      }
    //  Edit GL Entry
    case EDIT_GL_ENTRY_SUCCESS:
      debugger
      let LineItems;
      const { accountList } = action.payload;
      if (action.payload.row.source === 'Invoice') {
        // FOR GETTING COA OBJ BY ID
        function coaObj(coaId) {
          let coaObj = accountList.find((x) => x._id === coaId)
          return coaObj
        }
        // CREDIT LINES
        let creditLines = action.payload.row.addCreditLines?.map((line, index) => {
          return {
            ...line,
            coa: coaObj(line?.coaId)
          }
        })
        // DEBIT LINES
        let debitLines = action.payload.row.addDebitLines?.map((line, index) => {
          return {
            ...line,
            coa: coaObj(line?.coaId)
          }
        })
        LineItems = creditLines.concat(debitLines);
      } else {
        LineItems = action.payload.row.addCreditLines.concat(action.payload.row.addDebitLines);
      }
      const { type } = action.payload
      const {
        _id,
        description,
        journalDate,
        source,
        voucherRef,
        attachments,
        statusType,
        taxType
      } = action.payload.row
      return {
        ...state,
        GLEntries: {
          ...state.GLEntries,
          values: {
            ...state.GLEntries.values,
            id: _id || null,
            journalDate: moment(journalDate).format('YYYY-MM-DD'),
            source: source,
            narration: description,
            voucherRef: voucherRef,
            statusType: statusType,
            taxType: taxType,
            create: type === 'view' || type === 'edit' ? false : true,
            view: type === 'view' ? true : false,
            edit: type === 'edit' ? true : false
          },
          saved_attachments: attachments
        },
        lineRows: LineItems,
      }
    // export to CSV
    case EXPORT_GL_ENTRY_SUCCESS:
      return { ...state, CSV_Success: true, CSV_Url: action.payload.path }
    default:
      return state
  }
}
