import {
  FINANCIAL_YEAR_CLEAR_FORM,
  FINANCIAL_YEAR_REQUEST,
  FINANCIAL_YEAR_STATUS,
  FINANCIAL_YEAR_SUCCESS,
  GET_FINANCIAL_YEAR_FAIL,
  GET_FINANCIAL_YEAR_REQUEST,
  GET_FINANCIAL_YEAR_SUCCESS,
  SUBMIT_FINANCIAL_YEAR_FAIL,
  SUBMIT_FINANCIAL_YEAR_REQUEST,
  SUBMIT_FINANCIAL_YEAR_SUCCESS,
  SUBMIT_TAX_DETAILS_FAIL,
  FINANCIAL_YEAR_CLEAR_SUBMIT_FORM
} from '../Actions/Constant'

let initialState = {
  financialSetting: {
    date: '',
    month: '',
    year:'',
    validfor: '',
    create: true,
    view: false,
    edit: false
  },
  financialListError: {
    loading: false,
    inProgress: false,
    isSuccess: false,
    error: false,
    errorMsg: ''
  },
  CSV_Success: false,
  CSV_Url: '',
  financialList: [],
  openPeriods: [],
  financialListId: '',
  loading: false,
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: ''
}

export function financialSetting(state = initialState, action) {
  switch (action.type) {
    // clear All
    case FINANCIAL_YEAR_CLEAR_FORM:
      return {
        ...state,
        financialSetting: {
          date: '',
          month: '',
          year:'',
          create: true,
          view: false,
          edit: false
        },
        financialListError: {
          loading: false,
          inProgress: false,
          isSuccess: false,
          error: false,
          errorMsg: ''
        },
        financialList: [],
        openPeriods: [],
        financialListId: '',
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }
    // clear after submit financial setting
    case FINANCIAL_YEAR_CLEAR_SUBMIT_FORM:
      return {
        ...state,
        financialListError: {
          ...state.financialListError,
          loading: false,
          inProgress: false,
          isSuccess: false,
          error: false,
          errorMsg: ''
        },
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }

    //  Save New Account Details
    case FINANCIAL_YEAR_REQUEST:
      return { ...state }
    case FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        financialSetting: {
          ...state.financialSetting,
          [action.payload.name]: action.payload.value
        }
      }
    // getting all COA
    case GET_FINANCIAL_YEAR_REQUEST:
      return {
        ...state,
        financialListError: {
          ...state.financialListError,
          loading: true,
          inProgress: true,
          isSuccess: false,
          error: false,
          errorMsg: ''
        },
        financialList: []
      }
    case GET_FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        financialListError: {
          ...state.financialListError,
          loading: false,
          inProgress: false,
          isSuccess: true,
          error: false,
          errorMsg: ''
        },
        financialListId: action.payload._id,
        financialList: action.payload
      }
    case GET_FINANCIAL_YEAR_FAIL:
      return {
        ...state,
        financialListError: {
          ...state.financialListError,
          loading: false,
          inProgress: false,
          isSuccess: false,
          error: true,
          errorMsg: action.payload
        },
      }
    case FINANCIAL_YEAR_STATUS:
      // openingDate
      // closingDate
      let array = state.financialList;
      const {
        checked,
        index,
        rowIndex,
        list,
        type } = action.payload;
      let main = array[rowIndex]._id.status = checked;
      let mainObj = array[rowIndex]
      let InnerObj = mainObj.quarter[index].status = checked;
      return {
        ...state,
        loading: false,
        error: false,
        inProgress: false,
        isSuccess: false,
      }
      // PUSH OPEN/CLOSED PERIODS TO NEW ARRAY 
      // let duplicateCheck = state.openPeriods.find((x) => x.months === list._id);
      // const chkIndex = state.openPeriods.findIndex(item => item.months === list._id);
      // if (duplicateCheck) {
      //   state.openPeriods[chkIndex].status = checked;
      // } else {
      //   state.openPeriods.push({
      //     months: list._id,
      //     status: checked
      //   });
      // }

    // submit new tax 
    case SUBMIT_FINANCIAL_YEAR_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        inProgress: true,
        isSuccess: false,
        errorMsg: ''
      }
    case SUBMIT_FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        inProgress: false,
        isSuccess: true,
        errorMsg: ''
      }
    case SUBMIT_FINANCIAL_YEAR_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        inProgress: false,
        isSuccess: false,
        errorMsg: action.payload
      }
    default:
      return state
  }
}
