import moment from "moment";
import {
  GET_GENRAL_KPIS_REQUEST,
  GET_GENRAL_KPIS_SUCCESS,
  GET_GENRAL_KPIS_FAILED,
  GET_PAYMENT_VOUCHER_FOR_DASHBOARD_SUCCESS,
  GET_PAYMENT_VOUCHER_FOR_DASHBOARD_FAILED,
  GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_SUCCESS,
  GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_FAILED,
  GET_PAYMENT_VOUCHER_FOR_DASHBOARD_REQUEST,
  GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_REQUEST,
  GET_PROFIT_LOSS_DATES_SUCCESS,
  SET_DASHBOARD_FILTERS,
  SET_DASHBOARD_PERIOD,
  GET_CHART_FOR_ACCOUNT_SUCCESS,
  GET_CHART_FOR_ACCOUNT_REQUEST,
  GET_PROFIT_LOSS_REPORT_DASHBOARD_REQUEST,
  GET_PROFIT_LOSS_REPORT_DASHBOARD_SUCCESS,
  RESET_FILTERS,
  GET_PREVIOUS_DATES_PR_SUCCESS,
  GET_PROFIT_LOSS_REPORT_DASHBOARD_FAIL,
} from "../Actions/Constant";

let initialState = {
  GeneralKpis: [
    { parentAccount: "Assets", balanceTotal: 0 },
    { parentAccount: "Revenue", balanceTotal: 0 },
    { parentAccount: "Liabilities", balanceTotal: 0 },
    { parentAccount: "Equity", balanceTotal: 0 },
    { parentAccount: "Expenses", balanceTotal: 0 },
  ],
  paymentVoucher: [],
  depositVoucher: [],
  chartForAccount: [],
  profitLossReport: [],
  filters: {
    date: {
      start: "2023-01-01T07:56:06.269Z",
      end: "2023-01-31T19:00:00.000Z",
    },
    period: "thisMonth",
    compareWith: 2,
  },
  previousDate: [],
  // ------------
  GeneralKpisStatus: {
    loading: true,
    inProgress: true,
    isSuccess: false,
    error: false,
    errorMsg: "",
  },
  paymentVoucherStatus: {
    loading: true,
    inProgress: true,
    isSuccess: false,
    error: false,
    errorMsg: "",
  },
  depositVoucherStatus: {
    loading: true,
    inProgress: true,
    isSuccess: false,
    error: false,
    errorMsg: "",
  },
  chartForAccountStatus: {
    loading: true,
    inProgress: true,
    isSuccess: false,
    error: false,
    errorMsg: "",
  },
  profitLossReportStatus: {
    loading: true,
    inProgress: true,
    isSuccess: false,
    error: false,
    errorMsg: "",
  },
};

export function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    // getting all Genral Report Kpis
    case GET_GENRAL_KPIS_REQUEST:
      return {
        ...state,
        GeneralKpisStatus: {
          ...state.GeneralKpisStatus,
          loading: true,
          inProgress: true,
          isSuccess: false,
          error: false,
        },
      };
    case GET_GENRAL_KPIS_SUCCESS:
      return {
        ...state,
        GeneralKpis: action.payload,
        GeneralKpisStatus: {
          ...state.GeneralKpisStatus,
          loading: false,
          inProgress: false,
          isSuccess: true,
          error: false,
        },
      };
    case GET_GENRAL_KPIS_FAILED:
      return {
        ...state,
        GeneralKpisStatus: {
          ...state.GeneralKpisStatus,
          loading: false,
          inProgress: false,
          isSuccess: false,
          error: true,
          errorMsg: action.payload,
        },
        GeneralKpis: [],
      };
    // ---------------------------------
    case GET_PAYMENT_VOUCHER_FOR_DASHBOARD_REQUEST:
      return {
        ...state,
        paymentVoucherStatus: {
          ...state.paymentVoucherStatus,
          loading: true,
          inProgress: true,
          isSuccess: false,
          error: false,
        },
      };
    case GET_PAYMENT_VOUCHER_FOR_DASHBOARD_SUCCESS:
      return {
        ...state,
        paymentVoucher: action.payload,
        paymentVoucherStatus: {
          ...state.paymentVoucherStatus,
          loading: false,
          inProgress: false,
          isSuccess: true,
          error: false,
        },
      };
    case GET_PAYMENT_VOUCHER_FOR_DASHBOARD_FAILED:
      return {
        ...state,
        paymentVoucherStatus: {
          ...state.paymentVoucherStatus,
          loading: false,
          inProgress: false,
          isSuccess: false,
          error: true,
          errorMsg: action.payload,
        },
      };
    // --------------------------------
    case GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_REQUEST:
      return {
        ...state,
        depositVoucherStatus: {
          ...state.depositVoucherStatus,
          loading: true,
          inProgress: true,
          isSuccess: false,
          error: false,
        },
      };
    case GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_SUCCESS:
      return {
        ...state,
        depositVoucher: action.payload,
        depositVoucherStatus: {
          ...state.depositVoucherStatus,
          loading: false,
          inProgress: false,
          isSuccess: true,
          error: false,
        },
      };
    case GET_DEPOSIT_VOUCHER_FOR_DASHBOARD_FAILED:
      return {
        ...state,
        depositVoucherStatus: {
          ...state.depositVoucherStatus,
          loading: false,
          inProgress: false,
          isSuccess: false,
          error: true,
          errorMsg: action.payload,
        },
      };
    // ------------------------------------------
    case GET_CHART_FOR_ACCOUNT_REQUEST:
      return {
        ...state,
        chartForAccountStatus: {
          ...state.depositVoucherStatus,
          loading: true,
          inProgress: true,
          isSuccess: false,
        },
      };
    case GET_CHART_FOR_ACCOUNT_SUCCESS:
      return {
        ...state,
        chartForAccount: action.payload,
        chartForAccountStatus: {
          ...state.chartForAccountStatus,
          loading: false,
          inProgress: false,
          isSuccess: true,
          error: false,
        },
      };
    // ------------------------------------
    case GET_PROFIT_LOSS_REPORT_DASHBOARD_REQUEST:
      return {
        ...state,
        profitLossReportStatus: {
          ...state.profitLossReportStatus,
          loading: true,
          inProgress: true,
          isSuccess: false,
        },
      };
    case GET_PROFIT_LOSS_REPORT_DASHBOARD_SUCCESS:
      return {
        ...state,
        profitLossReport: action.payload.data,
        profitLossReportStatus: {
          ...state.profitLossReportStatus,
          loading: false,
          inProgress: false,
          isSuccess: true,
          error: false,
        },
      };
    case GET_PROFIT_LOSS_REPORT_DASHBOARD_FAIL:
      return {
        ...state,
        profitLossReport: {
          groupBalance: []
        },
        profitLossReportStatus: {
          ...state.profitLossReportStatus,
          loading: false,
          inProgress: false,
          isSuccess: false,
          error: true,
        },
      };
    // ----------------------------------------------
    case GET_PROFIT_LOSS_DATES_SUCCESS:
      return {
        ...state,
        filters: {
          ...state.filters,
          date: action.payload,
        },
      };

    case SET_DASHBOARD_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          date: {
            ...state.filters.date,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case SET_DASHBOARD_PERIOD:
      return {
        ...state,
        filters: {
          ...state.filters,
          period: action.payload,
        },
      };
    // resret all filters
    case RESET_FILTERS:
      return {
        ...state,
        filters: {
          date: {
            start: "2023-01-01T07:56:06.269Z",
            end: "2023-01-31T19:00:00.000Z",
          },
          period: "thisMonth",
          compareWith: 4,
        },
      };

    // -----Previous date------
    case GET_PREVIOUS_DATES_PR_SUCCESS:
      return {
        ...state,
        previousDate: action.payload.data,
      };

    default:
      return state;
  }
}
