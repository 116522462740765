import {
  GET_ACCOUNTS,
  SAVE_ACCOUNTS,
  FAIL_ACCOUNTS,
  GET_TAX_LIST,
  SAVE_TAX_LIST,
  FAIL_TAX_LIST,
  ACCOUNT_DETAILS_REQUEST,
  ACCOUNT_DETAILS_SAVE,
  GET_ALL_COA_REQUEST,
  GET_ALL_COA_SUCCESS,
  GET_ALL_COA_FAIL,
  CLEAR_ALL_COA_FORM,
  SUBMIT_ACCOUNT_DETAILS_REQUEST,
  SUBMIT_ACCOUNT_DETAILS_SUCCESS,
  SUBMIT_ACCOUNT_DETAILS_FAIL,
  GET_ACCOUNTS_BY_ID_REQUEST,
  GET_ACCOUNTS_BY_ID_SUCCESS,
  GET_ACCOUNTS_BY_ID_FAIL,
  EXPORT_COA_SUCCESS,
  GET_EXISTING_COA_LIST_SAVE,
  GET_EXISTING_COA_LIST_REQUEST,
  GET_EXISTING_COA_LIST_FAIL,
  GET_COA_BY_TYPE_SUCCESS,
  EXPORT_COA_FAIL
} from '../Actions/Constant'

let initialState = {
  coaList: [],
  accountDetails: {
    controlAccount: '',
    accountTypeID: '',
    accountType: '',
    accountParentName: '',
    name: '',
    code: '',
    acctualCode: '',
    description: '',
    taxId: '',
    controlAccount: false,
    level: ''
  },
  CSV_Success: false,
  CSV_Url: '',
  loading: false,
  CoaAccounts: [],
  accounts: [],
  taxList: [],
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: ''
}

export function chartofAccounts(state = initialState, action) {
  switch (action.type) {
    // clear All
    case CLEAR_ALL_COA_FORM:
      return {
        ...state,
        accountDetails: {
          controlAccount: '',
          accountTypeID: '',
          accountType: '',
          accountParentName: '',
          name: '',
          code: '',
          acctualCode: '',
          description: '',
          taxId: '',
          controlAccount: false,
          level: ''
        },
        CoaAccounts: [],
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }
    // getting all COA
    case GET_ALL_COA_REQUEST:
      return {
        ...state,
        loading: true,
        inProgress: true,
        error: false,
        coaList: []
      }
    case GET_ALL_COA_SUCCESS:
      return {
        ...state,
        loading: false,
        inProgress: false,
        error: false,
        coaList: action.payload
      }
    case GET_ALL_COA_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        inProgress: false,
        coaList: [],
        errorMsg: action.payload
      }
    // get COA on the base of account Type
    case GET_COA_BY_TYPE_SUCCESS:
      return { ...state, loading: true, CoaAccounts: action.payload }
    // getting Existing COA & Save list
    case GET_EXISTING_COA_LIST_REQUEST:
      return { ...state, loading: true, CoaAccounts: [] }
    case GET_EXISTING_COA_LIST_SAVE:
      return { ...state, loading: false, CoaAccounts: action.payload }
    case GET_EXISTING_COA_LIST_FAIL:
      return { ...state, loading: false, CoaAccounts: [], error: action.payload }
    // getting & Save account list
    case GET_ACCOUNTS:
      return { ...state, loading: true, accounts: [] }
    case SAVE_ACCOUNTS:
      return { ...state, loading: false, accounts: action.payload }
    case FAIL_ACCOUNTS:
      return { ...state, loading: false, accounts: [], error: action.payload }
    // getting & Save Tax list
    case GET_TAX_LIST:
      return { ...state, loading: true, taxList: [] }
    case SAVE_TAX_LIST:
      return { ...state, loading: false, taxList: action.payload }
    case FAIL_TAX_LIST:
      return { ...state, loading: false, taxList: [], error: action.payload }
    //  Save New Account Details
    case ACCOUNT_DETAILS_REQUEST:
      return { ...state }
    case ACCOUNT_DETAILS_SAVE:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          [action.payload.name]: action.payload.value
        }
      }
    //  Get Account Details by ID
    case GET_ACCOUNTS_BY_ID_REQUEST:
      return { ...state }
    case GET_ACCOUNTS_BY_ID_SUCCESS:
      const { accountType, accountTypeID, name, code, description, taxId, controlAccount, level, ChartsOfAcc, accountParentName, Account } = action.payload
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          controlAccount: controlAccount,
          accountParentName: accountParentName,
          accountType: accountType,
          accountTypeID: accountTypeID || '',
          name: name || '',
          code: code,
          acctualCode: code,
          description: description,
          taxId: taxId,
          controlAccount: controlAccount,
          level: level
        }
      }
    // export to CSV 
    case EXPORT_COA_SUCCESS:
      return { ...state, CSV_Success: true, CSV_Url: action.payload.path }
    case EXPORT_COA_FAIL:
      return { ...state, CSV_Success: false, CSV_Url: action.payload }
    // submit new account form details
    case SUBMIT_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        inProgress: true,
        isSuccess: false,
        errorMsg: ''
      }
    case SUBMIT_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        inProgress: false,
        isSuccess: true,
        errorMsg: ''
      }
    case SUBMIT_ACCOUNT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        inProgress: false,
        isSuccess: false,
        errorMsg: action.payload
      }
    default:
      return state
  }
}
