import { lazy } from "react";
import { Navigate } from "react-router-dom";



/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/

/*****Pages******/
const Dashboard1 = lazy(() => import("../views/dashboards/Dashboard1"));

/*****Tables******/
const BasicTable = lazy(() => import("../views/Finance/GL Module/index"));
const AddGLEntry = lazy(() => import("../views/Finance/GL Module/GLForm"));

// form elements
const ExAutoComplete = lazy(() =>
  import("../views/FormElements/ExAutoComplete")
);
const ExButton = lazy(() => import("../views/FormElements/ExButton"));
const ExCheckbox = lazy(() => import("../views/FormElements/ExCheckbox"));
const ExRadio = lazy(() => import("../views/FormElements/ExRadio"));
const ExSlider = lazy(() => import("../views/FormElements/ExSlider"));
const ExSwitch = lazy(() => import("../views/FormElements/ExSwitch"));
// form layouts
const FormLayouts = lazy(() => import("../views/FormLayouts/FormLayouts"));
const Auth = lazy(() => import("../Authorization/Auth"));
const ChartofAccount = lazy(() => import("../views/Finance/ChartofAccounts/index"));
const GLIquiryForm = lazy(() => import("../views/Finance/GLInquiry/index"));
const ValidateEntry = lazy(() => import("../views/Finance/GL Module/ValidateEntry"));
const Report = lazy(() => import("../views/Finance/Reports/Report"));
const JournalReport = lazy(() => import("../views/Finance/Reports/JournalReports"));
const GeneralLedgerReport = lazy(() => import("../views/Finance/Reports/GeneralLedgerReport"));
const GeneralLedgerSummary = lazy(() => import("../views/Finance/Reports/GeneralLedgerSummary"));
const TrailBalance = lazy(() => import("../views/Finance/Reports/TrialBalance"));
const TrailBalancePw = lazy(() => import("../views/Finance/Reports/TrailBalancePw"));
const ProfitLoss = lazy(() => import("../views/Finance/Reports/ProfitLossReport"));
const BalanceSheet = lazy(() => import("../views/Finance/Reports/BalanceSheet"));
const SaleTaxReport = lazy(() => import("../views/Finance/Reports/SaleTaxReport"));
const CashFlowStatement = lazy(() => import("../views/Finance/Reports/CashFlowStatement"));
const CashFlowDetail = lazy(() => import("../views/Finance/Reports/CashFlowDetail"));
const PaymentAccountDetails = lazy(() => import("../views/Finance/Payment Account Deatils/Index.js"));
const AddPaymentAccountDetails = lazy(() => import("../views/Finance/Payment Account Deatils/PaymentAcctDetailsForm.js"));
const DespositVoucher = lazy(() => import("../views/Finance/DepositVoucher/index"));
const PaymentVoucher = lazy(() => import("../views/Finance/PaymentVoucher/index"));
const AddPaymentVoucher = lazy(() => import("../views/Finance/PaymentVoucher/PaymentVoucherForm"));
const AddDespositVoucher = lazy(() => import("../views/Finance/DepositVoucher/DepositVoucherForm"));
const FinancialSetting = lazy(() => import("../views/Finance/Configurations/FinancialSetting"));
const AddAccounts = lazy(() => import("../views/Finance/Configurations/AddAccounts"));
const AddTax = lazy(() => import("../views/Finance/Configurations/AddTax"));
const GeneralSetup = lazy(() => import("../views/Finance/Configurations/GeneralSetup"));
const AccountCodeSetup = lazy(() => import("../views/Finance/Configurations/AccountsSetting"));
const DefaultAccounts = lazy(() => import("../views/Finance/Configurations/Default Accounts/index"));


/*****Routes******/

export const AuthRoutes = [
  {
    path: "/",
    // element: <AuthLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "/login", exact: true, element: <Auth /> },
    ]
  }
]


const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/dashboard", exact: true, element: <Dashboard1 /> },
      { path: "/GLTable", element: <BasicTable /> },
      { path: "/AddGLEntry", element: <AddGLEntry /> },
      { path: "/ValidateEntry", element: <ValidateEntry /> },
      { path: "/ChartofAccounts", element: <ChartofAccount /> },
      { path: "/GLIquiry", element: <GLIquiryForm /> },
      { path: "/JournalReport", element: <JournalReport /> },
      { path: "/Report", element: <Report /> },
      { path: "/GeneralLedgerReport", element: <GeneralLedgerReport /> },
      { path: "/GeneralLedgerSummary", element: <GeneralLedgerSummary /> },
      { path: "/TrailBalance", element: <TrailBalance /> },
      { path: "/TrailBalancePw", element: <TrailBalancePw /> },
      { path: "/ProfitLoss", element: <ProfitLoss /> },
      { path: "/BalanceSheet", element: <BalanceSheet /> },
      { path: "/SaleTax", element: <SaleTaxReport /> },
      { path: "/CashFlowStatement", element: <CashFlowStatement /> },
      { path: "/CashFlowDetail", element: <CashFlowDetail /> },
      { path: "/PaymentAccountDetails", element: <PaymentAccountDetails /> },
      { path: "/AddPaymentAccountDetails", element: <AddPaymentAccountDetails /> },
      { path: "/DespositVoucher", element: <DespositVoucher /> },
      { path: "/PaymentVoucher", element: <PaymentVoucher /> },
      { path: "/AddPaymentVoucher", element: <AddPaymentVoucher /> },
      { path: "/AddDepositVoucher", element: <AddDespositVoucher /> },
      { path: "/FinancialConfig", element: <FinancialSetting /> },
      { path: "/AddAccounts", element: <AddAccounts /> },
      { path: "/AddTax", element: <AddTax /> },
      { path: "/GeneralSetup", element: <GeneralSetup /> },
      { path: "/AccountCodeSetup", element: <AccountCodeSetup /> },
      { path: "/DefaultAccounts", element: <DefaultAccounts /> },
      { path: "/form-elements/slider", element: <ExSlider /> },
      { path: "/form-elements/switch", element: <ExSwitch /> },
    ],
  },
];

export default ThemeRoutes;
