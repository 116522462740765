import { CallToActionSharp } from '@material-ui/icons'
import moment from 'moment'
import {
  GS_ACCOUNT_SETUP_REQUEST,
  GS_ACCOUNT_SETUP_SUCCESS,
  CLEAR_GS_ACCOUNT_SETUP,
  GET_GENERAL_CONFIG_REQUEST,
  GET_GENERAL_CONFIG_SUCCESS,
  GET_GENERAL_CONFIG_FAIL,
  UPDATE_GENERAL_CONFIG_SUCCESS,
  UPDATE_GENERAL_CONFIG_REQUEST,
  UPDATE_GENERAL_CONFIG_FAIL,
  CLEAR_GS_STATE,
  SAVE_ACCOUNT_CODES_SUCCESS
} from '../Actions/Constant'

let initialState = {
  enterpriseDetails: {
    newUser: false,
    newTenant: false,
    enterpriseName: '',
    coaName: '',
    phoneNumber: '',
    email: '',
    companyId: '',
    GSTNumber: '',
    primaryCurrency: '',
    FYstartDate: '',
    decimalRounding: '',
    coaCodeLength: '',
    controlAcctLenght: '',
    controlAcctCodeLevel:'',
    accountingBases: '',
    finyear: '',
    address: '',
    interCompanyTXN: false,
    logo: '',
    timeZone: '',
  },
  controlAcctDetails: {
    asset: '',
    liability: '',
    expense: '',
    revenue: '',
    equity: ''
  },
  inProgress: false,
  isSuccess: false,
  error: false,
  errorMsg: ''
}

export function GeneralSetupReducer(state = initialState, action) {
  switch (action.type) {
    // clear All
    case CLEAR_GS_ACCOUNT_SETUP:
      return {
        ...state,
        enterpriseDetails: {
          newUser: false,
          newTenant: false,
          enterpriseName: '',
          coaName: '',
          phoneNumber: '',
          email: '',
          companyId: '',
          GSTNumber: '',
          primaryCurrency: '',
          FYstartDate: '',
          decimalRounding: '',
          coaCodeLength: '',
          controlAcctLenght: '',
          controlAcctCodeLevel:'',
          accountingBases: '',
          address: '',
          interCompanyTXN: false,
          logo: '',
          timeZone: '',
          finyear: '',
        },
        controlAcctDetails: {
          asset: '',
          liability: '',
          expense: '',
          revenue: '',
          equity: ''
        },
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }

    // clear state after error from backend
    case CLEAR_GS_STATE:
      return {
        ...state,
        inProgress: false,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }

    //  Save New Account Details
    case GS_ACCOUNT_SETUP_REQUEST:
      return { ...state }
    case GS_ACCOUNT_SETUP_SUCCESS:
      return {
        ...state,
        enterpriseDetails: {
          ...state.enterpriseDetails,
          [action.payload.name]: action.payload.value
        }
      }
    case SAVE_ACCOUNT_CODES_SUCCESS:
      const {
        Equity,
        Expenses,
        Liabilities,
        Assets,
        Revenue
      } = action.payload
      return {
        ...state,
        controlAcctDetails: {
          asset: Assets,
          liability: Liabilities,
          expense: Expenses,
          revenue: Revenue,
          equity: Equity,
        }
      }
    // GET GENERAL CONFIGURATIONS
    case GET_GENERAL_CONFIG_REQUEST:
      return { ...state }
    case GET_GENERAL_CONFIG_SUCCESS:
      if (action.payload) {
        let date = moment(action.payload.FYstartDate).format("YYYY-MM-DD")
        return {
          ...state,
          enterpriseDetails: {
            ...state.enterpriseDetails,
            newUser: false,
            newTenant: action.payload.newTenant,
            enterpriseName: action.payload.enterpriseName,
            coaName: action.payload.coaName,
            phoneNumber: action.payload.phoneNumber,
            email: action.payload.email,
            companyId: action.payload.companyId,
            GSTNumber: action.payload.GSTNumber,
            primaryCurrency: action.payload.primaryCurrency,
            FYstartDate: date,
            decimalRounding: action.payload.decimalRounding,
            coaCodeLength: action.payload.coaCodeLength,
            controlAcctLenght: action.payload.controlAcctLenght,
            controlAcctCodeLevel:action.payload.controlAcctCodeLevel,
            accountingBases: action.payload.accountingBases,
            address: action.payload.address,
            interCompanyTXN: action.payload.interCompanyTXN,
            logo: action.payload.logo,
            timeZone: action.payload.timeZone,
            finyear: action.payload.finyear,

          }
        }
      } else {
        return {
          ...state,
          enterpriseDetails: {
            ...state.enterpriseDetails,
            newUser: true,
            newTenant: true,
            enterpriseName: '',
            coaName: '',
            phoneNumber: '',
            email: '',
            companyId: '',
            GSTNumber: '',
            primaryCurrency: '',
            FYstartDate: '',
            decimalRounding: '',
            coaCodeLength: '',
            controlAcctLenght: '',
            controlAcctCodeLevel:'',
            accountingBases: '',
            address: '',
            interCompanyTXN: false,
            logo: '',
            timeZone: '',
            finyear: '',

          },
          controlAcctDetails: {
            asset: '',
            liability: '',
            expense: '',
            revenue: '',
            equity: ''
          },
        }
      }

    // Update GENERAL CONFIGURATIONS
    case UPDATE_GENERAL_CONFIG_REQUEST:
      return {
        ...state,
        inProgress: true,
        isSuccess: false,
        error: false,
        errorMsg: ''
      }
    case UPDATE_GENERAL_CONFIG_SUCCESS:
      return {
        ...state,
        inProgress: false,
        isSuccess: true,
        error: false,
        errorMsg: ''
      }
    case UPDATE_GENERAL_CONFIG_FAIL:
      return {
        ...state,
        inProgress: false,
        isSuccess: false,
        error: true,
        errorMsg: action.payload
      }
    default:
      return state
  }
}
