import { EXPORT_REQUEST, EXPORT_SUCCESS, EXPORT_FAIL, RESET_EXPORT_FIELDS } from "../Actions/Constant";


let initialState = {
    inProgress: false,
    isSuccess: false,
    error: false,
    errorMsg: null,
    data: ''
}

export function exportToExcel(state = initialState, action) {
    switch (action.type) {
        // clear All
        case RESET_EXPORT_FIELDS:
            return {
                ...state,
                inProgress: false,
                isSuccess: false,
                error: false,
                errorMsg: null,
                data: ''
            }
        case EXPORT_REQUEST:
            return {
                ...state,
                inProgress: true,
                isSuccess: false,
                error: false,
                errorMsg: null,
                data: ''
            }
        case EXPORT_SUCCESS:
            return {
                ...state,
                inProgress: false,
                isSuccess: true,
                error: false,
                errorMsg: null,
                data: action.payload
            }
        case EXPORT_FAIL:
            return {
                ...state,
                inProgress: false,
                isSuccess: false,
                error: true,
                errorMsg: action.payload,
                data: ''
            }
        default:
            return state
    }
}
